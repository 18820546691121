import React, {useEffect, useState} from 'react';
import Header from './Header';
import Footer from './Footer'
import { Link } from 'react-router-dom';
import axios from 'axios';
import appConstant from './constant/constant.json';
function Signup() {
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [resultMessage, setResultMessage] = useState('');
    const [salesRep, setSalesRep] = useState([]);
    const togglePassword = (e) => {
        setPasswordShown(!passwordShown);
    };
    const toggleConfirmPassword = (e) => {
        setConfirmPasswordShown(!confirmPasswordShown);
    };
    useEffect(() => {
        salesRepData();
    }, []);
    // sign up
    const [signupInputValues, setSignupInputValues] = useState({
        fname : "",
        lname : "",
        number : "",
        email : "",
        password : "",
        cpassword : "",
        company : "",
        address : "",
        state : "",
        city : "",
        country : "",
        zip : "",
        saleRep : "",
        resaleId: ""
    });
    const handleSignupChange = (e) => {
        const {name, value} = e.target;
        setSignupInputValues({...signupInputValues, [name]: value});
    }
    const [signupValidation, setSignupValidation] = useState({
        fname : "",
        lname : "",
        number : "",
        email : "",
        password : "",
        cpassword : "",
        address : "",
        state : "",
        city : "",
        country : "",
        zip : ""
    });
    const checkSignupValidation = () => {
        let errors = JSON.parse(JSON.stringify(signupValidation));

        //first validation
        const alphabetCond = /^[a-zA-Z ]*$/;
        if (!signupInputValues.fname) {
        errors.fname = "First name is required";
        } else if (!signupInputValues.fname.match(alphabetCond)) {
        errors.fname = "Please enter valid first name";
        } else {
        errors.fname = "";
        }
        //last validation
        if (!signupInputValues.lname) {
        errors.lname = "Last name is required";
        } else if (!signupInputValues.lname.match(alphabetCond)) {
        errors.lname = "Please enter valid last name";
        } else {
        errors.lname = "";
        }
        //number validation
        const numberCond = /^[0-9\b]+$/;
        if (!signupInputValues.number) {
        errors.number = "Number is required";
        } else if (!signupInputValues.number.match(numberCond)) {
        errors.number = "Please enter valid number";
        } else {
        errors.number = "";
        }

        // email validation
        const emailCond = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!signupInputValues.email) {
          errors.email = "Email is required";
        } else if (!signupInputValues.email.match(emailCond)) {
          errors.email = "Please enter valid email address";
        } else {
          errors.email = "";
        }

        //password validation
        const password = signupInputValues.password;
        const passReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6})/;
        if (!password) {
          errors.password = "Password is required";
        }
        // else if (!password.match(passReg)) {
        //   errors.password = "Password must be at least 6 characters and must include at least one upper case letter, one lower case letter, one special character and one numeric digit.";
        // }
        else {
          errors.password = "";
        }
        //confirm password validation
        const cpassword = signupInputValues.cpassword;
        if (!cpassword) {
        errors.cpassword = 'Repeat Password';
        } else if (cpassword !== password) {
        errors.cpassword = 'Both password should match';
        } else {
        errors.cpassword = '';
        }
        //address validation
    if (!signupInputValues.address) {
        errors.address = "Address is required";
        }else {
        errors.address = "";
        }
        //country validation
        if (!signupInputValues.country) {
          errors.country = "Country is required";
          }else {
          errors.country = "";
        }
        //state validation
        if (!signupInputValues.state) {
          errors.state = "State is required";
          }else {
          errors.state = "";
        }
        //city validation
        if (!signupInputValues.city) {
          errors.city = "City is required";
          }else {
          errors.city = "";
        }
        //zip validation
        if (!signupInputValues.zip) {
          errors.zip = "Zip is required";
          }else {
          errors.zip = "";
        }
        setSignupValidation(errors);
        if(errors.fname === '' && errors.lname === '' && errors.number === '' && errors.email === '' && errors.password === '' && errors.cpassword === '' && errors.address === '' && errors.country === '' && errors.state === ''&& errors.city === '' && errors.zip === ''){
          return true;
        }else{
          return false;
        }
    };
    const signUp = (e) => {
        e.preventDefault();
        const isSuccess = checkSignupValidation();
        if(isSuccess) {
            setIsLoading(true);
            const signupData = new FormData();
            signupData.append('first_name', signupInputValues.fname);
            signupData.append('last_name', signupInputValues.lname);
            signupData.append('phone_number', signupInputValues.number);
            signupData.append('email', signupInputValues.email);
            signupData.append('password', signupInputValues.password);
            signupData.append('company', signupInputValues.company);
            signupData.append('address', signupInputValues.address);
            signupData.append('country', signupInputValues.country);
            signupData.append('state', signupInputValues.state);
            signupData.append('city', signupInputValues.city);
            signupData.append('zip_code', signupInputValues.zip);
            signupData.append('sales_rep_id', signupInputValues.saleRep);
            signupData.append('resale_id', signupInputValues.resaleId);
            const params = {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            };
            axios.post(`${appConstant.API_URL}/user/signup`, signupData, params)
                .then(res => {
                    setIsLoading(false);
                    if (res?.data?.status === 200) {
                        localStorage.setItem("token", res?.data?.data?.token);
                        localStorage.setItem("full_name", res?.data?.data?.full_name);
                        localStorage.setItem("account_number", res?.data?.data?.account_number);
                        // localStorage.setItem("user_type", res?.data?.data?.user_type);
                        localStorage.setItem('cartTotal', 0);
                        window.location.href = '/';
                        setResultMessage(res?.data?.message + 'Please close this window.');
                    }else {
                        setResultMessage(res?.data?.message);
                    }
                })
                .catch(error => {
                     setIsLoading(false);
                    // props.changeLogin(true);
                });
        }
    }
    const salesRepData = () => {
        const params = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        axios.get(`${appConstant.API_URL}/brands/sales_rep`, params)
            .then(res => {
                if(res?.data?.status === 200){
                    setSalesRep(res?.data?.data);
                }
            })
            .catch(error => {
            });
    }
  return (
    <>
        <Header/>
        <div className='static-page-banner'></div>
        <div className='static-page-content page-spacing mb-4'>
          <h6 className='heading'>Signup</h6>
            <div className='user-content'>
                {resultMessage && <p className='formErrors mb-2'>{resultMessage}</p>}
                <form className='signup-form' onSubmit={signUp}>
                    <label>First Name:</label>
                    <input type="text" placeholder="First Name" name="fname" value={signupInputValues.fname} onChange={(e) => handleSignupChange(e)}/>
                    {signupValidation.fname && <p className='formErrors'>{signupValidation.fname}</p>}
                    <label>Last Name:</label>
                    <input type="text" placeholder="Last Name" name="lname" value={signupInputValues.lname} onChange={(e) => handleSignupChange(e)}/>
                    {signupValidation.lname && <p className='formErrors'>{signupValidation.lname}</p>}
                    <label>Mobile Number:</label>
                    <input type="text" placeholder="Mobile Number" name="number" value={signupInputValues.number} onChange={(e) => handleSignupChange(e)}/>
                    {signupValidation.number && <p className='formErrors'>{signupValidation.number}</p>}
                    <label>Email:</label>
                    <input type="text" placeholder="Email" name="email" value={signupInputValues.email} onChange={(e) => handleSignupChange(e)}/>
                    {signupValidation.email && <p className='formErrors'>{signupValidation.email}</p>}
                    <label>Password:</label>
                    <div className='position-relative'>
                    <input type={passwordShown ? "text" : "password"} placeholder="Password" name="password" value={signupInputValues.password} onChange={(e) => handleSignupChange(e)}/>
                    <img src={passwordShown ? "/images/eyeshow.png" : "/images/eyehide.png"} className="password-hide" alt="password" onClick={togglePassword}/>
                    </div>
                    {signupValidation.password && <p className='formErrors'>{signupValidation.password}</p>}
                    <label>Confirm Password:</label>
                    <div className='position-relative'>
                    <input type={confirmPasswordShown ? "text" : "password"} placeholder="Confirm Password" name="cpassword" value={signupInputValues.cpassword} onChange={(e) => handleSignupChange(e)}/>
                    <img src={confirmPasswordShown ? "/images/eyeshow.png" : "/images/eyehide.png"} className="password-hide" alt="password" onClick={toggleConfirmPassword}/>
                    </div>
                    {signupValidation.cpassword && <p className='formErrors'>{signupValidation.cpassword}</p>}
                    <label>Company:</label>
                    <input type="text" placeholder='Enter Company Name' name='company'  value={signupInputValues.company} onChange={(e) => handleSignupChange(e)}/>
                    <label>Address:</label>
                    <input type='text' name='address' placeholder='Address' value={signupInputValues.address} onChange={(e) => handleSignupChange(e)}/>
                    {signupValidation.address && <p className='formErrors'>{signupValidation.address}</p>}
                    <label>City</label>
                    <input type="text" placeholder='Enter City' name='city' value={signupInputValues.city} onChange={(e) => handleSignupChange(e)}/>
                    {signupValidation.city && <p className='formErrors'>{signupValidation.city}</p>}
                    <label>State:</label>
                    <select name="state" value={signupInputValues.state} onChange={(e) => handleSignupChange(e)}>
                        <option value="">Select State</option>
                        {appConstant.STATES.length > 0  && appConstant.STATES?.map((result, i) =>
                            <option value={result} key={i}>{result}</option>
                        )}
                    </select>
                    {signupValidation.state && <p className='formErrors'>{signupValidation.state}</p>}
                    <label>Country</label>
                    <select name="country"  value={signupInputValues.country} onChange={(e) => handleSignupChange(e)}>
                        <option value="">Select Country</option>
                        <option value="USA">USA</option>
                    </select>
                    {signupValidation.country && <p className='formErrors'>{signupValidation.country}</p>}
                    <label>Zip/Postal Code</label>
                    <input type="text" placeholder='Enter Zip/Postal Code' name='zip'  value={signupInputValues.zip} onChange={(e) => handleSignupChange(e)}/>
                    {signupValidation.zip && <p className='formErrors'>{signupValidation.zip}</p>}
                    <label>Sales Rep (Optional)</label>
                    <select name="saleRep"  value={signupInputValues.saleRep} onChange={(e) => handleSignupChange(e)}>
                        <option value="">Select Sales Rep</option>
                        {salesRep?.length > 0 && salesRep?.map((result, i) =>
                            <>
                                <option value={result?.id}>{result?.name}</option>
                            </>
                        )}
                    </select>
                    <label>Resale ID (Optional)</label>
                    <input type='text' placeholder='Enter Resale Id' name='resaleId'  value={signupInputValues.resaleId} onChange={(e) => handleSignupChange(e)}/>
                    {isLoading ? <div className='text-center mb-4'><button type='submit' className="buttons">Signup <img className='ms-2' src='/images/default.gif' width={'16px'} height={'16px'} alt={'signup'}/></button></div> :
                        <div className='text-center mb-4'><button type='submit' className="buttons">Signup</button></div>
                    }
                    <h6 className='tagline mb-2 text-center'>OR</h6>
                    <h6 className="tagline text-center">Already have account? <Link to='/login' className='links'>Login</Link></h6>
                </form>
            </div>
        </div>
        <Footer/>
    </>
  )
};

export default Signup;
