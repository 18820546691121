import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from '../shared/usePagination';
const Pagination = props => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        className
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <ul
            className={classnames('pagination-container pagination justify-content-between alyya-pagination', { [className]: className })}
        >
            {/* Left navigation arrow */}
            {currentPage === 1 && <li className="page-item disabled d-none d-sm-inline"><a className="page-link">&#129120; &nbsp; Previous</a></li>}
            {currentPage !== 1 && <li className="page-item d-none d-sm-inline" onClick={onPrevious} ><a className="page-link">&#129120; &nbsp; Previous</a></li>}
            <div style={{display : 'flex'}}>
            {paginationRange.map(pageNumber => {
                // If the pageItem is a DOT, render the DOTS unicode character
                if (pageNumber === DOTS) {
                    return <li className="pagination-item dots" key={'dots'}>&#8230; &nbsp;&nbsp;</li>;
                }

                // Render our Page Pills
                return (
                    (pageNumber === currentPage) ? <li className="page-item active" key={pageNumber}><a className="page-link">{pageNumber}</a></li> : <li className="page-item"><a className="page-link"  onClick={() => onPageChange(pageNumber)}>{pageNumber}</a></li>
                );
            })}
            </div>
            {/*  Right Navigation arrow */}
            {currentPage === lastPage && <li className="page-item disabled d-none d-sm-inline"><a className="page-link">Next &nbsp; &#129122;</a></li> }
            {currentPage !== lastPage && <li className="page-item d-none d-sm-inline" onClick={onNext}><a className="page-link">Next &nbsp; &#129122;</a></li> }
        </ul>
    );
};

export default Pagination;