import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <>
    {/* benefits  */}
    <section className="benefit-items mb-4">
          <div className="single-benefit flex-fill">
            <div className="sb-icon">
                <img src="/images/icon-1.png" alt="icon-1" />
            </div>
            <div className="sb-text">
                <h6 className='title'>Free Shipping</h6>
                <p className='desc'>On orders over $200</p>
            </div>
          </div>
          <div className="single-benefit flex-fill">
            <div className="sb-icon">
                <img src="/images/icon-2.png" alt="icon-2" />
            </div>
            <div className="sb-text">
                <h6 className='title'>Delivery On Time</h6>
                <p className='desc'>Orders shipped by UPS</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="single-benefit flex-fill">
                <div className="sb-icon">
                    <img src="/images/icon-3.png" alt="icon-3" />
                </div>
                <div className="sb-text">
                    <h6 className='title'>Safe Payment</h6>
                    <p className='desc'>Through PayPal and Square</p>
                </div>
            </div>
          </div>
        </section>
        {/* footer  */}
        <footer className="footer-section">
            <div className="row">
                <div className="col-md-4">
                    <Link to="/"><img className='logo' src="/images/logo.png" alt="logo"/></Link>
                    <ul className='details'>
                        <li className='mb-2'>Address : S & S Fashions, Inc. 941 Longfellow Ave Bronx, NY, 10474 USA</li>
                        <li className='mb-3'>
                            <div className='d-flex'>
                                <div className='me-3'>Phone :</div>
                                <div>
                                <a href="tel: +17183280001" >+1 718-328 0001</a> <br/> 
                                <a href="tel: +12122900009" >+1 212-290 0009</a><br/>
                                <a href="tel: +13476735200">+1 347 673 5200</a>
                                </div>
                            </div> 
                        </li>
                        <li>Email : <a href="mailto:info@sandsfashions.com">info@sandsfashions.com</a></li>
                    </ul> 
                </div>
                <div className="col-md-3">
                    <div className="footer-widget text-left">
                        <h5 className="title">Our Services</h5>
                        <ul className='details'>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/shop">Shop</Link></li>
                            {localStorage.getItem('token') && <li><Link to="/orders">Orders</Link></li>}
                            <li><Link to="/cart">Shopping Cart</Link></li>
                            <li><Link to="/file/credit_application.pdf" target={'_BLANK'}>Download Credit Application Form</Link></li>
                            <li><Link to="/file/fw9.pdf" target={'_BLANK'}>Download W-9 Form</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="footer-widget text-left">
                        <h5 className="title">Information</h5>
                        <ul className='details'>
                            <li><Link to="/about-us">About Us</Link></li>
                            <li><Link to="/key-brands">Key Brands</Link></li>
                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                            <li><Link to="/returns">Returns</Link></li>
                            <li><Link to="/shipping-coverage">Shipping Coverage</Link></li>
                            <li><Link to="/terms-conditions">Terms & Conditions</Link></li>
                            <li><Link to="/terms-use">Terms Of Use</Link></li>
                            <li><Link to="/contact-us">Contact Us</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="newslatter-item footer-left">
                        <h5 className="title">Follow Us</h5>
                        <div className="footer-social pt-0 mb-4">
                            <a href="https://www.facebook.com/ssfashionsny/" target="_blank" rel="noreferrer"><img src="/images/facebook.png" alt="facebook"/></a>
                            <a href="https://twitter.com/Sands_Fashions" target="_blank" rel="noreferrer"><img src="/images/twitter.png" alt="twitter"/></a>
                            <a href="https://www.instagram.com/sandsfashions/" target="_blank" rel="noreferrer"><img src="/images/instagram.png" alt="instagram"/></a>
                            <a href="https://api.whatsapp.com/send?phone=13476735200&text=Hello" target="_blank" rel="noreferrer"><img src="/images/whatsapp.png" alt="whatsapp"/></a>
                            <a href="https://www.youtube.com/channel/UCunX_RavQ4zAWM9iIO2nqhw" target="_blank" rel="noreferrer"><img src="/images/youtube.png" alt="youtube"/></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-reserved">
                <div className="copyright-text">                        
                    Copyright &copy; {(new Date().getFullYear())}  S & S Fashions Inc. All Rights Reserved.
                </div>
                <div className="payment-pic">
                    <img src="/images/payment-method.png" alt="payment" />
                </div>                
            </div>
        </footer>
    </>
  )
};

export default Footer;