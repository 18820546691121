import React from 'react';
import { Link } from 'react-router-dom';

function Sidebar(props) {
    return (
    <>
        <div className='order-detail sidebar'>
            <div className='d-flex'>
                <Link className={"dropdown-item ps-2 " + (props.id === "1" ? 'active' : '')} to="/my-profile">My Profile</Link>                       	
            </div>
            <div className='d-flex'>
                <Link className={"dropdown-item ps-2 " + (props.id === "2" ? 'active' : '')} to="/orders">My Orders</Link>                      	
            </div>
            <div className='d-flex'>
                <Link className={"dropdown-item ps-2 " + (props.id === "3" ? 'active' : '')} to="/shipping-address">Shipping Address</Link>                          	
            </div>
            <div className='d-flex'>
                <Link className={"dropdown-item ps-2 " + (props.id === "4" ? 'active' : '')} to="/manage-saved-cards">Manage Saved Cards</Link>                          	
            </div>
            <div className='d-flex'>
                <Link className="dropdown-item ps-2" to="/logout">Logout</Link>                          	
            </div>
        </div>
        <div className='sidebar-mobile'>
            <div className='d-flex'>
                <select className='mb-0 me-3' name="pages">
                    <option value='profile'>My Profile</option>
                    <option value='profile'>My Orders</option>
                    <option value='profile'>Shipping Address</option>
                    {/* <option value='profile'>Billing Address</option> */}
                </select>
                <button className='buttons'>Go</button>
            </div>
            
        </div>
    </>
  )
};
export default Sidebar;