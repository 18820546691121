import React from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { Link } from 'react-router-dom';

function ShippingCoverage() {
  return (
    <>
        {/* header  */}
        <Header/>
        <div className='static-page-banner'></div>
        <div className='static-page-content page-spacing mb-4'>          
          <div className='breadcrumbs'>
            <Link to="/">Home / </Link> <strong>Shipping Coverage</strong>
          </div>
          <h6 className='heading'>Shipping Coverage</h6>          
          <p className='content'>Effective 1/1/2020 free freight on all online orders over $200. Orders placed before cut-off time will be shipped the same business day. Orders received after the cut-off time will be shipped the following business day. Truck shipments will be shipped the same business day for orders taken before 3:00 pm local time. Express shipments are available upon request. S & S Fashions, Inc. will not be responsible for any delay in the delivery of the shipment of goods, or for any damages suffered by reason of such delay. All shipments are F.O.B. from origin ship point.</p>
          <h6 className='title'>LOCAL PICK UP ORDERS</h6>
          <ul className='static-page-list'>
            <li>941 Longfellow Ave, Bronx, NY 10474</li>
            <li><strong>Office:</strong> 212-290 0009 Fax: 212-208 2953</li>
            <li><strong>Warehouse:</strong> PH:718-328 0001 FAX:718-328 0044</li>
          </ul>    
          <p className='content'>You may pick up your orders Monday through Friday. Please allow three hours for processing orders. Orders placed after the cut-off time will be available for pickup at 11:00 to 12.00pm the following business day or after 5:30 to 6:00PM</p>
          <h6 className='title'>FREE FREIGHT</h6>
          <p className='content'>Free freight on all ONLINE orders over $200. Now includes WHITE T-SHIRTS and SALE ITEMS! Excludes The All-Star Chair (FT002) and oversized travel bag styles GBW-2, 92060, 92601, and 92603. Free Freight does not apply to C.O.D. charges and is only good for orders shipped within the continental United States.</p>
        </div>
        {/* footer  */}
        <Footer/>
    </>
  )
};
export default ShippingCoverage;