import React, {useState} from 'react';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import appConstant from './constant/constant.json';
import { Link } from 'react-router-dom';

function Login() {
    const [passwordShown, setPasswordShown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [resultMessage, setResultMessage] = useState('');
    const togglePassword = (e) => {
        setPasswordShown(!passwordShown);
    };
    // login
    const [loginInputValues, setLoginInputValues] = useState({
        email : "",
        password : "",
    });
    const [checked, setChecked] = useState(false);
    const handleLoginChange = (e) => {
        const {name, value} = e.target;
        setLoginInputValues({...loginInputValues, [name]: value});
    }
    const [loginValidation, setLoginValidation] = useState({
        email : "",
        password : ""
    });
    const checkLoginValidation = () => {
        let errors = JSON.parse(JSON.stringify(loginValidation));

        // email validation
        const emailCond = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!loginInputValues.email) {
          errors.email = "Email is required";
        } else if (!loginInputValues.email.match(emailCond)) {
          errors.email = "Please enter valid email address";
        } else {
          errors.email = "";
        }

        //password validation
        const password = loginInputValues.password;
        // const passReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6})/;
        if (!password) {
          errors.password = "Password is required";
        }
        // else if (!password.match(passReg)) {
        //   errors.password = "Password must be at least 6 characters and must include at least one upper case letter, one lower case letter, one special character and one numeric digit.";
        // }
        else {
          errors.password = "";
        }
        setLoginValidation(errors);
        if(errors.email === '' && errors.password === ''){
          return true;
        }else{
          return false;
        }
    };
    const logIn = (e) => {
        e.preventDefault();
        const isSuccess = checkLoginValidation();
        if(isSuccess) {
            setIsLoading(true);
            const loginData = new FormData();
            loginData.append('email', loginInputValues.email );
            loginData.append('password', loginInputValues.password );
            const params = {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*"
            };
            axios.post(`${appConstant.API_URL}/user/login`, loginData, params)
                .then(res => {
                    setIsLoading(false);
                    if (res.data.status === 200) {
                        localStorage.setItem("token", res?.data?.data?.token);
                        localStorage.setItem("full_name", res?.data?.data?.full_name);
                        localStorage.setItem("account_number", res?.data?.data?.account_number);
                        // localStorage.setItem("user_type", res?.data?.data?.user_type);
                        // localStorage.setItem('cartTotal', 0);
                        window.location.href = '/';
                        setResultMessage(res?.data?.message + ' Please close this window.');
                    }else {
                        setResultMessage(res?.data?.message);
                    }
                })
                .catch(error => {
                    setIsLoading(false);
                    // props.changeLogin(true);
                });
        }
    }
  return (
    <>
        <Header/>
        <div className='static-page-banner'></div>
        <div className='static-page-content page-spacing mb-4'>
          <h6 className='heading'>Login</h6>
            <div className='user-content'>
                {resultMessage && <p className='formErrors mb-2'>{resultMessage}</p>}
                <form className='login-form' onSubmit={(e) => {logIn(e)}}>
                    <label>Email:</label>
                    <input type="text" placeholder="Enter Email" name='email' value={loginInputValues.email} onChange={(e) => handleLoginChange(e)}/>
                    {loginValidation.email && <p className='formErrors'>{loginValidation.email}</p>}
                    <label>Password:</label>
                    <div className='position-relative'>
                    <input type={passwordShown ? "text" : "password"} placeholder="Enter Password" name='password' value={loginInputValues.password} onChange={(e) => handleLoginChange(e)}/>
                    <img src={passwordShown ? "/images/eyeshow.png" : "/images/eyehide.png"} className="password-hide" alt="password" onClick={togglePassword}/>
                    </div>
                    {loginValidation.password && <p className='formErrors'>{loginValidation.password}</p>}
                    <label className="d-flex align-items-center justify-content-between my-4">
                        <div className="form-check form-switch mb-0">
                            <input id='remember' className="form-check-input mb-0" type="checkbox" name="hide-billing" checked={checked} onChange={e => setChecked(e.target.checked)}/>
                            <label htmlFor='remember' className='ms-3 mb-0'>Remember me</label>
                        </div>
                        <Link to='/forgot-password' className='forget-link links'>Forgot Password</Link>
                    </label>
                    {isLoading ? <div className='text-center mb-4'><button type='submit' className="buttons">Login <img className='ms-2' src='/images/default.gif' width={'16px'} height={'16px'} alt={'default'}/></button></div> :
                        <div className='text-center mb-4'><button type='submit' className="buttons">Login</button></div>
                    }
                    <h6 className='tagline mb-2 text-center'>OR</h6>
                    <h6 className="tagline text-center">Don't have account? <Link to='/signup' className='links'>Signup</Link></h6>
                </form>
            </div>
        </div>
        {/* footer  */}
        <Footer/>
    </>
  )
};

export default Login;
