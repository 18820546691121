import React, {useEffect, useState} from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Sidebar from './shared/Sidebar';
import { Link } from 'react-router-dom';
import axios from 'axios';
import appConstant from './shared/constant/constant.json';
import LoadingSpinner from './shared/Loader';
function Profile() {
  useEffect(() => {
    getUserDetails();
}, []);
  const [resultMessage, setResultMessage] = useState('');
  const [oldPasswordShown, setOldPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isLoadingPwd, setIsLoadingPwd] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const toggleOldPassword = (e) => {
    setOldPasswordShown(!oldPasswordShown);
  };
  const toggleNewPassword = (e) => {
    setNewPasswordShown(!newPasswordShown);
  };
  const toggleConfirmPassword = (e) => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };
  const [inputValues, setInputValue] = useState({
    fname: "",
    lname: "",
    dob: "",
    number: "",
    email: "",
    cell: "",
    // acc_number: "",
    company_email: "",
    annual_purchases: "",
    paperless_email: "",
    website: "",
    resale_id: "",
    // resale_certificate: "",
    address: "",
    country: "",
    state: "",
    city: "",
    zip: ""
  });
  const [resaleCertificate, setResaleCertificate] = useState();
  const uploadResaleCertificate = event => {
    const fileData = event.target.files[0];
    if(fileData !== undefined) {
      setResaleCertificate(fileData);
    }
};
  function handleChange(e) {
    const { name, value } = e.target;
    setInputValue({ ...inputValues, [name]: value });
  }
  const [validation, setValidation] = useState({
    fname: "",
    lname: "",
    dob: "",
    number: "",
    cell: "",
    // acc_number: "",
    company_email: "",
    annual_purchases: "",
    paperless_email: "",
    website: "",
    resale_id: "",
    // resale_certificate: "",
    address: "",
    country: "",
    state: "",
    city: "",
    zip: ""
  });
  const checkValidation = () => {
    let errors = JSON.parse(JSON.stringify(validation));

    //first validation
    const alphabetCond = /^[a-zA-Z ]*$/;
    if (!inputValues.fname) {
    errors.fname = "First name is required";
    } else if (!inputValues.fname.match(alphabetCond)) {
    errors.fname = "Please enter valid first name";
    } else {
    errors.fname = "";
    }
    //last validation
    if (!inputValues.lname) {
    errors.lname = "Last name is required";
    } else if (!inputValues.lname.match(alphabetCond)) {
    errors.lname = "Please enter valid last name";
    } else {
    errors.lname = "";
    }
    //number validation
    const numberCond = /^[0-9\b]+$/;
    if (!inputValues.number) {
    errors.number = "Number is required";
    } else if (!inputValues.number.match(numberCond)) {
    errors.number = "Please enter valid number";
    } else {
    errors.number = "";
    }
    //cell validation
    if (!inputValues.cell) {
      errors.cell = "";
    } else if (!inputValues.cell.match(numberCond)) {
    errors.cell = "Please enter valid number";
    } else {
    errors.cell = "";
    }  
    //dob validation
    if (!inputValues.dob) {
      errors.dob = "DOB is required";
      }else {
      errors.dob = "";
    }
    //address validation
    if (!inputValues.address) {
      errors.address = "Address is required";
    }else {
    errors.address = "";
    }
    //country validation
    if (!inputValues.country) {
      errors.country = "Country is required";
      }else {
      errors.country = "";
    }
    //state validation
    if (!inputValues.state) {
      errors.state = "State is required";
      }else {
      errors.state = "";
    }
    //city validation
    if (!inputValues.city) {
      errors.city = "City is required";
      }else {
      errors.city = "";
    }
    //zip validation
    if (!inputValues.zip) {
      errors.zip = "Zip is required";
    }else if (!inputValues.zip.match(numberCond)) {
        errors.zip = "Please enter valid zip";
    } else {
      errors.zip = "";
    }

    setValidation(errors);
    if(errors.fname === '' && errors.lname === '' && errors.number === '' && errors.cell === '' && errors.dob === '' && errors.address === '' && errors.country === '' && errors.state === ''&& errors.city === '' && errors.zip === ''){
      return true;
    }else{
      return false;
    }
  };
  const getUserDetails = () => {
    setIsLoading(true);
    const userDetailsData = new FormData();
    userDetailsData.append('token', localStorage.getItem('token') );
    const params = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
    };
    axios.post(`${appConstant.API_URL}/user`, userDetailsData, params)
          .then(res => {
              setIsLoading(false);
              if (res?.data?.status === 200) {
                setUserDetail(res?.data?.data);
                  const newData = {
                  fname: res?.data?.data?.first_name,
                  lname: res?.data?.data?.last_name,
                  dob: res?.data?.data?.dob,
                  number: res?.data?.data?.phone_number,
                  email: res?.data?.data?.email,
                  cell: res?.data?.data?.cell_phone,
                  // acc_number: res?.data?.data?.account_number,
                  company_email: res?.data?.data?.credit_rep,
                  annual_purchases: res?.data?.data?.annual_apparel_purchases,
                  paperless_email: res?.data?.data?.invoice_email,
                  website: res?.data?.data?.website,
                  resale_id: res?.data?.data?.resale_id,
                  address: res?.data?.data?.address,
                  country: res?.data?.data?.country,
                  state: res?.data?.data?.state,
                  city: res?.data?.data?.city,
                  zip: res?.data?.data?.zip_code
                  }
                  setInputValue(newData);
              }else {

              }
          })
          .catch(error => {
              setIsLoading(false);
              // props.changeLogin(true);
          });
  }
  const updateProfile = (e) => {
    e.preventDefault();
    const isSuccess = checkValidation();
    if(isSuccess) {
      setIsLoadingButton(true);
      const userDetailsData = new FormData();
      userDetailsData.append('token', localStorage.getItem('token') );
      userDetailsData.append('first_name', inputValues.fname );
      userDetailsData.append('last_name', inputValues.lname );
      userDetailsData.append('dob', inputValues.dob );
      userDetailsData.append('phone_number', inputValues.number );
      userDetailsData.append('cell_phone', inputValues.cell );
      userDetailsData.append('credit_rep', inputValues.company_email );
      userDetailsData.append('annual_apparel_purchases', inputValues.annual_purchases );
      userDetailsData.append('invoice_email', inputValues.paperless_email );
      userDetailsData.append('website', inputValues.website );
      userDetailsData.append('resale_id', inputValues.resale_id );
      userDetailsData.append('address', inputValues.address );
      userDetailsData.append('country', inputValues.country );
      userDetailsData.append('state', inputValues.state );
      userDetailsData.append('city', inputValues.city );
      userDetailsData.append('zip_code', inputValues.zip );
      if(resaleCertificate){
        userDetailsData.append('resale_certificate', resaleCertificate);
      }
      const params = {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*"
      };
      axios.post(`${appConstant.API_URL}/user/update`, userDetailsData, params)
            .then(res => {
                setIsLoadingButton(false);
                if (res?.data?.status === 200) {
                  window.location = window.location.href;
                }else {

                }
            })
            .catch(error => {
                setIsLoadingButton(false);
                // props.changeLogin(true);
            });
    }        
  } 

  // change password 
    const [pwdInputValues, setPwdInputValues] = useState({
        oldPassword : "",
        newPassword : "",
        confirmPassword : ""
    });
    const handlePwdChange = (e) => {
        const {name, value} = e.target;
        setPwdInputValues({...pwdInputValues, [name]: value});
    }
    const [pwdValidation, setPwdValidation] = useState({
        oldPassword : "",
        newPassword : "",
        confirmPassword : ""
    });
    const checkpwdValidation = () => {
        let errors = JSON.parse(JSON.stringify(pwdValidation));

        //old password validation
        const oldPassword = pwdInputValues.oldPassword;
        // const passReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6})/;
        if (!oldPassword) {
          errors.oldPassword = "Old Password is required";
        }
        // else if (!oldPassword.match(passReg)) {
        //   errors.oldPassword = "Password must be at least 6 characters and must include at least one upper case letter, one lower case letter, one special character and one numeric digit.";
        // }
        else {
          errors.oldPassword = "";
        }

        //new password validation
        const newPassword = pwdInputValues.newPassword;
        if (!newPassword) {
          errors.newPassword = "New Password is required";
        }
        // else if (!newPassword.match(passReg)) {
        //   errors.newPassword = "Password must be at least 6 characters and must include at least one upper case letter, one lower case letter, one special character and one numeric digit.";
        // }
        else {
          errors.newPassword = "";
        }
        //confirm password validation
        const confirmPassword = pwdInputValues.confirmPassword;
        if (!confirmPassword) {
        errors.confirmPassword = 'Repeat New Password';
        } else if (confirmPassword !== newPassword) {
        errors.confirmPassword = 'Both password should match';
        } else {
        errors.confirmPassword = '';
        }

        setPwdValidation(errors);
        if(errors.oldPassword === '' && errors.newPassword === '' && errors.confirmPassword === ''){
          return true;
        }else{
          return false;
        }
    };
    const changePassword = (e) => {
        e.preventDefault();
        const isSuccess = checkpwdValidation();
        if(isSuccess) {
          setIsLoadingPwd(true);
          const changePwd = new FormData();
            changePwd.append('token', localStorage.getItem('token') );
            changePwd.append('old_pwd', pwdInputValues.oldPassword );
            changePwd.append('new_pwd', pwdInputValues.newPassword );
            const params = {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*"
            };
            axios.post(`${appConstant.API_URL}/user/update_password`, changePwd, params)
                .then(res => {
                    setIsLoadingPwd(false);
                    if (res.data.status === 200) {

                        setResultMessage(res?.data?.message);
                    }else {
                        setResultMessage(res?.data?.message);
                    }
                })
                .catch(error => {
                    setIsLoadingPwd(false);
            });
        }        
    }
  return (
    <>
        {/* header  */}
        <Header/>
        <div className='page-spacing cart-section'>
          <div className='breadcrumbs'>
              <Link to="/">Home / </Link> <strong>Profile</strong>
          </div>
          <div className='row'>
            <div className='col-md-3 mb-4'>
                <div className='card'>
                <div className="card-body">
                    <h6 className='title'>MY ACCOUNT</h6>
                    <Sidebar id='1'/>
                </div>
                </div>
            </div>
            <div className='col-md-9'>
              <h6 className='title mb-4'>User Information</h6>
              {isLoading ? <LoadingSpinner/> :
              <form onSubmit={updateProfile}>
                <div className='row'>
                  <div className='col-sm-6'>
                    <label>First Name</label>
                    <input type='text' name='fname' placeholder='First Name' value={inputValues.fname} onChange={(e) => handleChange(e)}/>
                    {validation.fname && <p className='formErrors'>{validation.fname}</p>}
                  </div>
                  <div className='col-sm-6'>
                    <label>Last Name</label>
                    <input type='text' name='lname' placeholder='Last Name' value={inputValues.lname} onChange={(e) => handleChange(e)}/>
                    {validation.lname && <p className='formErrors'>{validation.lname}</p>}
                  </div>
                  <div className='col-sm-6'>
                    <label>Date of Birth</label>
                    <input type='date' name='dob' value={inputValues.dob} onChange={(e) => handleChange(e)}/>
                    {validation.dob && <p className='formErrors'>{validation.dob}</p>}
                  </div>                  
                  <div className='col-sm-6'>
                    <label>Email Id</label>
                    <input type='text' name='email' disabled placeholder='Email' value={inputValues.email} onChange={(e) => handleChange(e)}/>
                    {validation.email && <p className='formErrors'>{validation.email}</p>}
                  </div>
                  <div className='col-sm-6'>
                    <label>Phone Number</label>
                    <input type='text' name='number' placeholder='Phone Number' value={inputValues.number} onChange={(e) => handleChange(e)}/>
                    {validation.number && <p className='formErrors'>{validation.number}</p>}
                  </div>
                  <div className='col-sm-6'>
                    <label>Cell Number</label>
                    <input type='text' name='cell' placeholder='Phone Number' value={inputValues.cell} onChange={(e) => handleChange(e)}/>
                    {validation.cell && <p className='formErrors'>{validation.cell}</p>}
                  </div>
                </div> 
                <hr/>
                {/* <h6 className='title my-4'>User Settings</h6>
                <div className='row'>
                  <div className='col-sm-6'>
                    <label>Default Shipping Method</label>
                    <select name="default_shipping" value={inputValues.default_shipping} onChange={(e) => handleChange(e)}>
                      <option value="0">None</option>
                      <option value="1">Will Call / Pick Up</option>
                      <option value="2">Ground</option>
                    </select>
                    {validation.default_shipping && <p className='formErrors'>{validation.default_shipping}</p>}
                  </div> 
                  <div className='col-sm-6'>
                    <label>Default Billing Method</label>
                    <select name="default_billing" value={inputValues.default_billing} onChange={(e) => handleChange(e)}>
                      <option value="0">None</option>
                      <option value="1">eCheck</option>
                      <option value="2">Credit Card</option>
                      <option value="3">Debit Card</option>
                    </select>
                    {validation.default_billing && <p className='formErrors'>{validation.default_billing}</p>}
                  </div>               
                </div> 
                <hr/> */}
                <h6 className='title my-4'>Company Information</h6>
                <div className='row'>
                  <div className='col-sm-6'>
                    <label className='d-inline'>Account Number:</label> <span>{userDetail.account_number}</span>
                  </div>
                  <div className='col-sm-6'>
                    <label className='d-inline'>Sales Rep:</label> <span>{userDetail.credit_rep}</span> 
                  </div>
                </div> 
                <hr/>
                <h6 className='title my-4'>Company Settings</h6>
                <div className='row'>
                  <div className='col-sm-6'>
                    <label>Paperless Invoicing Emails </label>
                    <input type='text' name='paperless_email' placeholder='Invoice Email' value={inputValues.paperless_email} onChange={(e) => handleChange(e)}/>
                    {validation.paperless_email && <p className='formErrors'>{validation.paperless_email}</p>}
                  </div>
                  <div className='col-sm-6'>
                    <label>Website</label>
                    <input type='text' name='website' placeholder='Website' value={inputValues.website} onChange={(e) => handleChange(e)}/>
                    {validation.website && <p className='formErrors'>{validation.website}</p>}
                  </div> 
                  <div className='col-sm-6'>
                    <label>Annual Apparel Purchases</label>
                    <select name="annual_purchases" value={inputValues.annual_purchases} onChange={(e) => handleChange(e)}>
                      <option value="0">None</option>
                      <option value="1">Less than $30,000</option>
                      <option value="2">$30,000 - $50,000</option>
                      <option value="3">$50,000 - $99,999</option>
                      <option value="4">$100,000 - $300,000</option>
                      <option value="5">$300,001 - $499,999</option>
                      <option value="6">$500,000 - $1,000,000</option>
                      <option value="7">Greater than $1,000,000</option>
                    </select>
                    {validation.annual_purchases && <p className='formErrors'>{validation.annual_purchases}</p>}
                  </div>
                  <div className='col-sm-6'>
                    <label>Resale ID</label>
                    <input type='text' name='resale_id' placeholder='Resale ID' value={inputValues.resale_id} onChange={(e) => handleChange(e)}/>
                    {validation.resale_id && <p className='formErrors'>{validation.resale_id}</p>}
                  </div>
                  <div className='col-sm-6'>
                    <label>Resale Certificate Upload</label>
                    <input className='p-0 rounded-0 h-auto' type='file' id="resaleCertificate" name='resaleCertificate' placeholder='Resale Certificate'  onChange={uploadResaleCertificate}/>
                    {userDetail?.resale_certificate && <a href={userDetail?.resale_certificate} target={'_BLANK'}><img src="/images/files.png" /></a>}
                  </div>                  
                </div> 
                <hr/>
                <h6 className='title my-4'>Billing Settings</h6>
                <div className='row'>
                  <div className='col-sm-6'>
                    <label>Address</label>
                    <input type='text' name='address' placeholder='Address' value={inputValues.address} onChange={(e) => handleChange(e)}/>
                    {validation.address && <p className='formErrors'>{validation.address}</p>}
                  </div>
                  <div className='col-sm-6'>
                    <label>Country</label>
                    <select name="country"  value={inputValues.country} onChange={(e) => handleChange(e)}>
                        <option value="">Select Country</option>
                        <option value="USA">USA</option>
                    </select>      
                    {validation.country && <p className='formErrors'>{validation.country}</p>}          
                  </div>
                  <div className='col-sm-6'>
                    <label>State</label>
                    <select name="state" value={inputValues.state} onChange={(e) => handleChange(e)}>
                        <option value="">Select State</option>
                      {appConstant.STATES.length > 0  && appConstant.STATES?.map((result, i) =>
                          <option value={result} key={i}>{result}</option>
                      )}
                    </select>  
                    {validation.state && <p className='formErrors'>{validation.state}</p>}             
                  </div>
                  <div className='col-sm-6'>
                    <label>City</label>
                    <input type="text" placeholder='Enter City' name='city' value={inputValues.city} onChange={(e) => handleChange(e)}/>  
                    {validation.city && <p className='formErrors'>{validation.city}</p>}            
                  </div>
                  <div className='col-sm-6'>
                    <label>Zip/Postal Code</label>
                    <input type="text" placeholder='Enter Zip/Postal Code' name='zip'  value={inputValues.zip} onChange={(e) => handleChange(e)}/> 
                    {validation.zip && <p className='formErrors'>{validation.zip}</p>}             
                  </div>
                </div>
                {isLoadingButton ? <div className='text-center mt-3'><button type='submit' className="buttons">Update <img className='ms-2' src='/images/default.gif' width={'16px'} height={'16px'}/></button></div> :
                  <div className='text-center mt-3'><button type='submit' className="buttons">Update</button></div>
                }            
              </form>
              }
              <hr className='my-4'/>
              <h6 className='title mb-4'>Change Password</h6>
              {resultMessage && <p className='formErrors mb-2'>{resultMessage}</p>}
              <form onSubmit={changePassword}>
                <div className='row'>
                  <div className='col-sm-4'>
                    <label>Old Password</label>
                    <div className='position-relative'>
                      <input type={oldPasswordShown ? "text" : "password"} name='oldPassword' placeholder='Old Password' value={pwdInputValues.oldPassword} onChange={(e) => handlePwdChange(e)}/>
                      <img src={oldPasswordShown ? "/images/eyeshow.png" : "/images/eyehide.png"} className="password-hide" alt="password" onClick={toggleOldPassword}/>
                    </div>
                    {pwdValidation.oldPassword && <p className='formErrors'>{pwdValidation.oldPassword}</p>}
                  </div>
                  <div className='col-sm-4'>
                    <label>New Password</label>
                    <div className='position-relative'>
                    <input type={newPasswordShown ? "text" : "password"} name='newPassword' placeholder='New Password' value={pwdInputValues.newPassword} onChange={(e) => handlePwdChange(e)}/>
                    <img src={newPasswordShown ? "/images/eyeshow.png" : "/images/eyehide.png"} className="password-hide" alt="password" onClick={toggleNewPassword}/>
                    </div>
                    {pwdValidation.newPassword && <p className='formErrors'>{pwdValidation.newPassword}</p>}
                  </div>
                  <div className='col-sm-4'>
                    <label>Confirm Password</label>
                    <div className='position-relative'>
                    <input type={confirmPasswordShown ? "text" : "password"} name='confirmPassword' placeholder='Confirm Password' value={pwdInputValues.confirmPassword} onChange={(e) => handlePwdChange(e)}/>
                    <img src={confirmPasswordShown ? "/images/eyeshow.png" : "/images/eyehide.png"} className="password-hide" alt="password" onClick={toggleConfirmPassword}/>
                    </div>
                    {pwdValidation.confirmPassword && <p className='formErrors'>{pwdValidation.confirmPassword}</p>}
                  </div>
                </div> 
                {isLoadingPwd ? <div className='text-center mt-3'><button type='submit' className="buttons">Change Password <img className='ms-2' src='/images/default.gif' width={'16px'} height={'16px'}/></button></div> :
                  <div className='text-center mt-3'><button type='submit' className="buttons">Change Password</button></div>
                }            
              </form>
            </div>
          </div>
        </div>
        {/* footer  */}
        <Footer/>
    </>
  )
};
export default Profile;