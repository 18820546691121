import React from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { Link } from 'react-router-dom';

function TermsUse() {
  return (
    <>
        {/* header  */}
        <Header/>
        <div className='static-page-banner'></div>
        <div className='static-page-content page-spacing mb-4'>          
          <div className='breadcrumbs'>
            <Link to="/">Home / </Link> <strong>Terms of Use</strong>
          </div>
          <h6 className='heading'>Terms of Use</h6>  
          <h6 className='title'>ACCEPTANCE OF TERMS</h6>        
          <p className='content'>Welcome to sandsfashions.com (the “Site”), a web site operated on behalf of S & S Fashions, Inc., LLC (“S&S”).</p>
          <p className='content'>The Terms and Conditions of Use include any posted guidelines or rules applicable to particular Content or Services (as defined below) and, as updated by S&S from time to time, are collectively referred to as the “Terms and Conditions” and govern the use of the Site. You should carefully read the Terms and Conditions before using the Site. You can review the most current version of the Terms and Conditions at any time at http://www.sandsfashions.com. By using the Site, you: (i) acknowledge that you have reviewed and agree to be bound by the Terms and Conditions; (ii) represent and warrant that you are at least eighteen (18) years old; and (iii) represent and warrant that you have the legal authority to accept the Terms and Conditions. If you work for or represent a business, you represent and agree that you are authorized by that business to enter into this Agreement on behalf of that business. This is a legally binding agreement. If you do not agree with the Terms and Conditions, you are not authorized to use the Site.</p>
          <h6 className='title'>ACCESS TO THE SITE</h6>
          <p className='content'>Access to certain portions of this Site may require login and password information provided only to users and/or businesses who have registered (the “Registered Users”). The login and password are provided for the use of the Registered User only and are not transferable. If you have received a login and password that belong to another person, you are not authorized to use that login account and we request that you notify S&S of the misdirected login information. Misuse of a login account, including, without limitation, unauthorized sharing of the login and password, may result, at S&S's sole discretion, in cancellation of the Registered User's registration and other remedies, and S&S shall have no liability to the Registered User.</p>
          <h6 className='title'>CONTENT AND SERVICES</h6>
          <p className='content'>The Site may provide certain content (including, without limitation, documents, photographs, advertisements, marketing materials, pricing information, text, graphics and images) and services that S&S may change or update from time to time (the “Content” and “Services”). The Content and Services offered on the Site are provided solely to enable S&S to provide information about its products.</p>
          <p className='content'>Any disclosures made through the Site should only be made after reviewing and agreeing to the Privacy Policy found at http://www.sandsfashions.com. In the event that any posted Content appears to be inappropriate for display on this Site for any reason, users and Registered Users are encouraged to contact S&S in accordance with Section 5 to request removal of such Content.</p>
          <h6 className='title'>USE OF THE SITE AND REGISTRATION</h6>
          <p className='content'>Use of the Site is voluntary. You may choose not to use the Site and you may choose not to register as a Registered User. You may use or download Content for your business only as specifically permitted on the Site, or for your personal, noncommercial purposes. </p>
          <p className='content'>The information S&S obtains from users and Registered Users will be used only in accordance with S&S's Privacy Policy. You may inactivate your login and password at any time by contacting S&S at the addresses provided in Section 5. In addition, you may have to pay a fee for access to the Site pursuant to a separate agreement with S&S.</p>
          <p className='content'>If you choose to register as a Registered User, you agree that you will be responsible for maintaining your password as confidential and for any activity that occurs as a result of your enabling or permitting another person or entity to use your password. You agree to immediately notify S&S by one of the methods in Section 5 in the event that (i) your password is lost or stolen, or (ii) you become aware of any unauthorized use of your password or of any other breach of security related to the Site. S&S is not responsible for any loss or damage arising from your failure to comply with the provisions of this section.</p>
          <h6 className='title'>CONTACT INFORMATION</h6>
          <p className='content'>You may contact S&S as follows: <br/>
          E-mail: pm@sandsfashions.com<br/>
          Telephone: 718-328 0001<br/>
          Mail: 941 Longfellow Ave, Bronx, NY 10474</p>
          <h6 className='title'>PROHIBITED BEHAVIOR</h6>
          <p className='content'>You are responsible for all Content that you upload, post, email or otherwise transmit using the Site. Likewise, you are responsible for respecting S&S's and third parties' rights with respect to Content that appears on the Site and agree not to download, email or otherwise transmit such Content in violation of S&S's and third parties' rights. You agree to use the Site solely to accommodate your own personal or business needs. You may not use or exploit any portion of the Site, Content or Services to provide any commercial services, “spam” or other unsolicited communications to third parties or offer any portion of the Site to any third party. You shall not use the Site or Services to:</p>          
          <ul className='static-page-list'>
            <li>Upload, post, email, or otherwise transmit any Content that infringes any patent, trademark, trade secret, copyright or other intellectual property right, privacy right, or publicity right of any person or entity. By uploading, posting, emailing or otherwise transmitting any Content, you represent and warrant that you have all rights to take such action with respect to the Content;</li>
            <li>Upload, post, email, or otherwise transmit any Content that is illegal, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, pornographic, sexually explicit, invasive of another's privacy, hateful or racially, ethnically, or otherwise offensive;</li>
            <li>Impersonate another person or entity, including without limitation, a representative of S&S or its officers, directors, employees or agents, falsely represent or misrepresent your affiliation with another person or entity, or forge headers or otherwise manipulate identifiers that would disguise the origin of any Content transmitted to or through the Site;</li>
            <li>Use the Site or Services for any purpose or in any manner that violates any local, state or federal law or regulation or the law or regulation of any foreign government;</li>
            <li>Directly or indirectly, intentionally disrupt or interfere with the Site in any manner that may materially adversely affect S&S or any third party; or</li>
            <li>Upload, post, email or otherwise transmit without authorization any material that contains personal or private information concerning any third party person or entity, including without limitation, phone number(s) or addresses, credit/debit cards, calling cards, account numbers, social security numbers, passwords or other similar information.</li>            
          </ul>    
          <p className='content'>Engaging in any of the foregoing prohibited actions may result, at S&S's sole discretion, in cancellation of the Registered User's registration and other remedies, and S&S shall have no liability to the Registered User.</p>
          <h6 className='title'>DISCLAIMER OF WARRANTIES </h6>
          <p className='content'>THE USE OF THE SITE AND SERVICES IS SOLELY AT YOUR OWN RISK. THE SITE AND SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. S&S EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND WITH RESPECT TO THE SITE (INCLUDING, WITHOUT LIMITATION, THE CONTENT AND/OR SERVICES OFFERED ON THE SITE) WHETHER EXPRESS OR IMPLIED INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. S&S EXPRESSLY DISCLAIMS ANY RESPONSIBILITY FOR THE ACTIONS OF ANY REGISTERED USER OR OTHER USER WHO USES THE SITE. SOME STATES DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU. S&S MAKES NO WARRANTY THAT THE SITE, CONTENT OR SERVICES WILL MEET YOUR REQUIREMENTS, OR WILL BE UNINTERRUPTED, TIMELY, SECURE, CURRENT, ACCURATE, COMPLETE OR ERROR-FREE OR THAT THE RESULTS THAT MAY BE OBTAINED BY USE OF THE SITE, CONTENT OR SERVICES WILL BE ACCURATE OR RELIABLE. YOU UNDERSTAND AND ACKNOWLEDGE THAT YOUR SOLE AND EXCLUSIVE REMEDY WITH RESPECT TO ANY DEFECT IN OR DISSATISFACTION WITH THE SITE, CONTENT OR SERVICES IS TO CEASE TO USE THE SITE OR SERVICES. YOU MAY HAVE OTHER RIGHTS WHICH MAY VARY FROM STATE TO STATE OR WITHIN NATIONAL JURISDICTIONS.</p>
          <h6 className='title'>EXCLUSIONS AND LIMITATION OF LIABILITY </h6>
          <p className='content'>YOU EXPRESSLY UNDERSTAND AND AGREE THAT S&S SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA LOSS, OR OTHER LOSSES (EVEN IF S&S HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES) RESULTING FROM ANY MATTER RELATED TO YOUR USE OF THE SITE, CONTENT OR SERVICES.</p>
          <h6 className='title'>INTELLECTUAL PROPERTY </h6>
          <ul className='static-page-list'>
            <li><strong>Copyright:</strong> The Content on the Site is owned by S&S and third parties and is protected by the United States Copyright Act of 1976, as amended, and the copyright laws of other countries. Certain materials are used by permission of their respective owners. The Content of the Site may not be copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way without the prior written permission of S&S, except that, subject to your compliance with the Terms and Conditions, S&S authorizes you to view or download a single copy of the Content provided that you keep intact all copyright, trademark, and other proprietary notices. Modification or use of Content for any other purpose is a violation of the rights of S&S, or third parties.</li> 
            <li><strong>Trademarks:</strong> “S & S FASHIONS, INC.” is a trademark in which S&S claims priority and ownership. Other trademarks are used with the permission of their respective owners. You agree not to use or display trademarks without the prior written consent of S&S or the owner of such mark.</li>            
            <li><strong>Reporting Violations:</strong> Pursuant to the Digital Millenium Copyright Act of 1998, 17 U.S.C. 512(c)(2), S&S's designated agent for notice of alleged copyright infringement in connection with the Site is pm@sandsfashions.com. To file a notice of infringement with S&S, the requirements specified in Title II of the Digital Millennium Copyright Act of 1998 must be fulfilled. The text of this statute can be found at the U.S. Copyright Office web site, located at http://www.copyright.gov.</li>            
          </ul> 
          <h6 className='title'>LINKS</h6>
          <p className='content'>The Site may include links to other World Wide Web sites. You acknowledge and agree that such links are provided for your convenience and do not reflect any endorsement by S&S with respect to the provider of such linked site or the linked site. S&S MAKES NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO ANY LINKED SITE: YOUR USE OF ANY LINKED SITE IS SOLELY AT YOUR OWN RISK.</p> 
          <h6 className='title'>INDEMNITY</h6>
          <p className='content'>You agree to defend, indemnify, and hold S&S, its officers, directors, employees, agents, licensors, and suppliers, harmless from and against any claims, actions, or demands, liabilities and settlements including without limitation, reasonable legal and accounting fees, resulting from, or alleged to result from, your violation of these Terms and Conditions or from your use or misuse of this Site.</p>
          <h6 className='title'>JURISDICTION AND VENUE</h6>
          <p className='content'>You agree that this Agreement and the relationship between you and S&S will be governed by the laws of the State of Illinois, without respect to its conflict of laws provisions and that venue with respect to any dispute between you and S&S will rest exclusively in the state or federal courts located in Will County, Illinois.</p> 
          <h6 className='title'>CHANGES</h6>
          <p className='content'>S&S may change the Terms and Conditions from time-to-time. If such a change is made, S&S will post the revised Terms and Conditions on the Site. S&S reserves the right to add to or change the Site or cease offering the Site (or any Content or Services on the Site) at any time and without liability. S&S reserves the right to refuse to offer access to the Site to anyone at anytime without notice.</p> 
          <h6 className='title'>MISCELLANEOUS</h6>
          <p className='content'>The Terms and Conditions constitute the entire agreement between you and S&S relating to the Site. There is no agency, partnership, joint venture, employee-employer or attorney-client relationship between any user and S&S arising solely through the use of the Site. The Terms and Conditions are binding upon your successors, assigns, heirs and executors. If any provision of the Terms and Conditions is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of the Terms and Conditions, which shall remain in full force and effect. The failure of S&S to exercise or enforce any right or provision of the Terms and Conditions shall not constitute a waiver of such right or condition. Any claim or cause of action arising out of or related to the Site, Content, Services or the Terms and Conditions must be filed within one year after such claim or cause of action arose. The section titles of the Terms and Conditions are merely for convenience and will not have any effect on the substantive meaning of this Agreement. </p> 
          <p className='content'>YOUR USE OF THE SITE INDICATES THAT YOU AGREE TO THE FOREGOING TERMS AND CONDITIONS. IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS, DO NOT USE THE SITE.</p> 
        </div>
        {/* footer  */}
        <Footer/>
    </>
  )
};
export default TermsUse;