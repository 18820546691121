import React, {useState} from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { Link } from 'react-router-dom';
import MetaTags from "react-meta-tags";

function ContactUs() {
  const [isLoading, setIsLoading] = useState(false);
  const [inputValues, setInputValue] = useState({
    fullname: "",
    email: "",
    phone: "",
    message: ""
  });
  function handleChange(e) {
    const { name, value } = e.target;
    setInputValue({ ...inputValues, [name]: value });
  }
  const [validation, setValidation] = useState({
    fullname: "",
    email: "",
    phone: "",
    message: ""
  });
  const checkValidation = () => {
    let errors = JSON.parse(JSON.stringify(validation));

    //fullname validation
    const alphabetCond = /^[a-zA-Z ]*$/;
    if (!inputValues.fullname.trim()) {
    errors.fullname = "Fullname is required";
    } else if (!inputValues.fullname.match(alphabetCond)) {
    errors.fullname = "Please enter valid fullname";
    } else {
    errors.fullname = "";
    }
    //number validation
    const numberCond = /^[0-9\b]+$/;
    if (!inputValues.phone.trim()) {
    errors.phone = "Number is required";
    } else if (!inputValues.phone.match(numberCond)) {
    errors.phone = "Please enter valid number";
    } else {
    errors.phone = "";
    }
    // email validation
    const emailCond = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!inputValues.email.trim()) {
      errors.email = "Email is required";
    } else if (!inputValues.email.match(emailCond)) {
      errors.email = "Please enter valid email address";
    } else {
      errors.email = "";
    }    
    //Message validation
    if (!inputValues.message.trim()) {
    errors.message = "Message is required";
    }else {
    errors.message = "";
    }
    setValidation(errors);
    if(errors.fullname === '' && errors.message === '' && errors.phone === '' && errors.email === ''){
      return true;
    }else{
      return false;
    }
  };
  const submitContactUs = (e) => {
    e.preventDefault();
    const isSuccess = checkValidation();
    if(isSuccess) {
      setIsLoading(true);
    }            
  }
  return (
    <>
      <MetaTags>
        <title>Contact Us - S & S Fashions Inc.</title>
        <meta name="description" content={"S & S Fashions Inc. Contact Us"} />
        <meta property="og:title" content={'Contact Us - S & S Fashions Inc.'} />
        <meta property="og:image" content={'/images/time-bg.jpg'} />
      </MetaTags>
        {/* header  */}
        <Header id='7'/>
        <div className='static-page-banner'></div>
        <div className='static-page-content contact-section mb-4'> 
          <div className='page-spacing'>      
            <div className='breadcrumbs'>
                <Link to="/">Home / </Link> <strong>Contact Us</strong>
            </div>
            <h6 className='heading'>Contact Us</h6>
            <p className='content'>Our trained Customer Service Representatives are ready and willing to help you place orders or answer questions. You can speak with our CSRs Monday thru Friday during normal business hours, 8:00am - 7:00pm EST.</p>  
            <div className='row'>
              <div className='col-md-6 order-md-1 order-2'>
                <div className='card mb-2'>
                  <div className='card-body'>
                    <h6><img className='icons' src='/images/address.png' alt='address'/><span className='title mb-0'>Address</span></h6> 
                    <p className='content mb-0'><strong>S & S Fashions, Inc.</strong> 941 Longfellow Ave, Bronx, NY 10474</p>
                  </div>
                </div>
                <div className='card mb-2'>
                  <div className='card-body'>
                    <h6><img className='icons' src='/images/email.png' alt='email'/><span className='title mb-0'>EMAIL</span></h6> 
                    <p className='content mb-0'>pm@sandsfashions.com<br/>zs@sandsfashions.com</p>
                  </div>
                </div>
                <div className='card mb-2'>
                  <div className='card-body'>
                    <h6><img className='icons' src='/images/telephone.png' alt='phone'/><span className='title mb-0'>Phone</span></h6> 
                    <p className='content mb-0'><strong>Office: </strong>212-290 0009<br/> 
                    <strong>Warehouse: </strong>718-328 0001</p> 
                  </div>
                </div>
                <div className='card'>
                  <div className='card-body'>
                    <h6><img className='icons' src='/images/fax.png' alt='fax-box'/><span className='title mb-0'>Fax</span></h6> 
                    <p className='content mb-0'><strong>Office: </strong>212-208 2953<br/> 
                    <strong>Warehouse: </strong>718-328 0044</p> 
                  </div>
                </div>              
              </div>  
              <div className='col-md-6 order-md-2 order-1 mb-4'>
                <div className='card'>
                  <div className='card-body'>
                    <form onSubmit={submitContactUs}>
                      <label>Full Name</label>
                      <input placeholder='Enter Full Name' type="text" name="fullname" value={inputValues.fullname} onChange={(e) => handleChange(e)}/>
                      {validation.fullname && <p className='formErrors'>{validation.fullname}</p>}
                      <label>Email</label>
                      <input placeholder='Enter Email' type="text" name="email" value={inputValues.email} onChange={(e) => handleChange(e)}/>
                      {validation.email && <p className='formErrors'>{validation.email}</p>}
                      <label>Phone</label>
                      <input placeholder='Enter Phone Number' type="text" name="phone" value={inputValues.phone} onChange={(e) => handleChange(e)}/>
                      {validation.phone && <p className='formErrors'>{validation.phone}</p>}  
                      <label>Message</label>                         
                      <textarea className='contact-textarea' name="message" placeholder='Your Massage' value={inputValues.message} onChange={(e) => handleChange(e)}></textarea> 
                      {validation.message && <p className='formErrors'>{validation.message}</p>}  
                      {isLoading ? <div className='text-center mt-4'><button type='submit' className='buttons'>Submit <img className='ms-2' src='/images/default.gif' width={'16px'} height={'16px'} alt={'default'}/></button></div> :
                      <div className='text-center mt-4'><button type='submit' className='buttons'>Submit</button></div>
                    }                                  
                    </form>  
                  </div>
                </div>
              </div>  
            </div>         
          </div> 
          <img src='/images/map.png' className='w-100' alt='map'/>
        </div>       
        {/* footer  */}
        <Footer/>
    </>
  )
};
export default ContactUs;