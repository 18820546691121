import React, {useEffect, useState} from 'react';
import ScrollToTop from './ScrollToTop';
import {Link, useParams} from 'react-router-dom';
import axios from 'axios';
import appConstant from './constant/constant.json';
function Header(props) {
    const getProp = useParams();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            setMyAccount(true);
            cartTotal();
        }
        brandsData();
        categoryData();
        bannerData();
        if(getProp && getProp.email && getProp.otp){
            checkRequest();
            setShowResetPasswordBox(true);
            setIsLoading(false);
            setLogin(false);
            setSignup(false);
            setForgot(false);
            setReset(true);
        }
    }, []);

    const Capitalize = (str) => {
        if(str){
            str = str.toLowerCase();
            return str.charAt(0).toUpperCase() + str.slice(1);
        }else{
            return str;
        }

    }
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [resultMessage, setResultMessage] = useState('');
    const [brands, setBrands] = useState([]);
    const [category, setCategory] = useState([]);
    const [banners, setBanners] = useState([]);
    const [showResetPasswordBox, setShowResetPasswordBox] = useState(false);
    const [isVerified, setIsVerified] = useState(true);
    const [checkRequestMessage, setCheckRequestMessage] = useState('');
    const togglePassword = (e) => {
        setPasswordShown(!passwordShown);
    };
    const toggleConfirmPassword = (e) => {
        setConfirmPasswordShown(!confirmPasswordShown);
    };

    const [login, setLogin] = useState(true);
    const [signup, setSignup] = useState(false);
    const [forgot, setForgot] = useState(false);
    const [reset, setReset] = useState(false);
    const [myAccount, setMyAccount] = useState(false);
    const showLogin = () => {
        setLogin(true);
        setSignup(false);
        setForgot(false);
        setReset(false);
    }
    const showSignup = () => {
        setLogin(false);
        setSignup(true);
        setForgot(false);
        setReset(false);
    }
    const showForgot = () => {
        setLogin(false);
        setSignup(false);
        setForgot(true);
        setReset(false);
    }
    // login
    const [loginInputValues, setLoginInputValues] = useState({
        email : "",
        password : "",
    });
    const [checked, setChecked] = useState(false);
    const handleLoginChange = (e) => {
        const {name, value} = e.target;
        setLoginInputValues({...loginInputValues, [name]: value});
    }
    const [loginValidation, setLoginValidation] = useState({
        email : "",
        password : ""
    });
    const checkLoginValidation = () => {
        let errors = JSON.parse(JSON.stringify(loginValidation));

        // email validation
        const emailCond = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!loginInputValues.email) {
          errors.email = "Email is required";
        } else if (!loginInputValues.email.match(emailCond)) {
          errors.email = "Please enter valid email address";
        } else {
          errors.email = "";
        }

        //password validation
        const password = loginInputValues.password;
        // const passReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6})/;
        if (!password) {
          errors.password = "Password is required";
        }
        // else if (!password.match(passReg)) {
        //   errors.password = "Password must be at least 6 characters and must include at least one upper case letter, one lower case letter, one special character and one numeric digit.";
        // }
        else {
          errors.password = "";
        }
        setLoginValidation(errors);
        if(errors.email === '' && errors.password === ''){
          return true;
        }else{
          return false;
        }
    };
    const logIn = (e) => {
        e.preventDefault();
        const isSuccess = checkLoginValidation();
        if(isSuccess) {
            setIsLoading(true);
            const loginData = new FormData();
            loginData.append('email', loginInputValues.email );
            loginData.append('password', loginInputValues.password );
            const params = {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*"
            };
            axios.post(`${appConstant.API_URL}/user/login`, loginData, params)
                .then(res => {
                    setIsLoading(false);
                    if (res.data.status === 200) {
                        setMyAccount(true);
                        localStorage.setItem("token", res?.data?.data?.token);
                        localStorage.setItem("full_name", res?.data?.data?.full_name);
                        localStorage.setItem("account_number", res?.data?.data?.account_number);
                        // localStorage.setItem("user_type", res?.data?.data?.user_type);
                        // localStorage.setItem('cartTotal', 0);
                        window.location = window.location.href;
                        setResultMessage(res?.data?.message + ' Please close this window.');
                    }else {
                        setResultMessage(res?.data?.message);
                    }
                })
                .catch(error => {
                    setIsLoading(false);
                    // props.changeLogin(true);
                });
        }
    }
    // sign up
    const [signupInputValues, setSignupInputValues] = useState({
        fname : "",
        lname : "",
        number : "",
        email : "",
        password : "",
        cpassword : "",
        company : "",
        address : "",
        state : "",
        city : "",
        country : "",
        zip : "",
        saleRep : "",
        resaleId: ""
    });
    const handleSignupChange = (e) => {
        const {name, value} = e.target;
        setSignupInputValues({...signupInputValues, [name]: value});
    }
    const [signupValidation, setSignupValidation] = useState({
        fname : "",
        lname : "",
        number : "",
        email : "",
        password : "",
        cpassword : "",
        address : "",
        state : "",
        city : "",
        country : "",
        zip : ""
    });
    const checkSignupValidation = () => {
        let errors = JSON.parse(JSON.stringify(signupValidation));

        //first validation
        const alphabetCond = /^[a-zA-Z ]*$/;
        if (!signupInputValues.fname) {
        errors.fname = "First name is required";
        } else if (!signupInputValues.fname.match(alphabetCond)) {
        errors.fname = "Please enter valid first name";
        } else {
        errors.fname = "";
        }
        //last validation
        if (!signupInputValues.lname) {
        errors.lname = "Last name is required";
        } else if (!signupInputValues.lname.match(alphabetCond)) {
        errors.lname = "Please enter valid last name";
        } else {
        errors.lname = "";
        }
        //number validation
        const numberCond = /^[0-9\b]+$/;
        if (!signupInputValues.number) {
        errors.number = "Number is required";
        } else if (!signupInputValues.number.match(numberCond)) {
        errors.number = "Please enter valid number";
        } else {
        errors.number = "";
        }

        // email validation
        const emailCond = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!signupInputValues.email) {
          errors.email = "Email is required";
        } else if (!signupInputValues.email.match(emailCond)) {
          errors.email = "Please enter valid email address";
        } else {
          errors.email = "";
        }

        //password validation
        const password = signupInputValues.password;
        const passReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6})/;
        if (!password) {
          errors.password = "Password is required";
        }
        // else if (!password.match(passReg)) {
        //   errors.password = "Password must be at least 6 characters and must include at least one upper case letter, one lower case letter, one special character and one numeric digit.";
        // }
        else {
          errors.password = "";
        }
        //confirm password validation
        const cpassword = signupInputValues.cpassword;
        if (!cpassword) {
        errors.cpassword = 'Repeat Password';
        } else if (cpassword !== password) {
        errors.cpassword = 'Both password should match';
        } else {
        errors.cpassword = '';
        }
        //address validation
    if (!signupInputValues.address) {
        errors.address = "Address is required";
        }else {
        errors.address = "";
        }
        //country validation
        if (!signupInputValues.country) {
          errors.country = "Country is required";
          }else {
          errors.country = "";
        }
        //state validation
        if (!signupInputValues.state) {
          errors.state = "State is required";
          }else {
          errors.state = "";
        }
        //city validation
        if (!signupInputValues.city) {
          errors.city = "City is required";
          }else {
          errors.city = "";
        }
        //zip validation
        if (!signupInputValues.zip) {
          errors.zip = "Zip is required";
          }else {
          errors.zip = "";
        }
        setSignupValidation(errors);
        if(errors.fname === '' && errors.lname === '' && errors.number === '' && errors.email === '' && errors.password === '' && errors.cpassword === '' && errors.address === '' && errors.country === '' && errors.state === ''&& errors.city === '' && errors.zip === ''){
          return true;
        }else{
          return false;
        }
    };
    const signUp = (e) => {
        e.preventDefault();
        const isSuccess = checkSignupValidation();
        if(isSuccess) {
            setIsLoading(true);
            const signupData = new FormData();
            signupData.append('first_name', signupInputValues.fname);
            signupData.append('last_name', signupInputValues.lname);
            signupData.append('phone_number', signupInputValues.number);
            signupData.append('email', signupInputValues.email);
            signupData.append('password', signupInputValues.password);
            signupData.append('company', signupInputValues.company);
            signupData.append('address', signupInputValues.address);
            signupData.append('country', signupInputValues.country);
            signupData.append('state', signupInputValues.state);
            signupData.append('city', signupInputValues.city);
            signupData.append('zip_code', signupInputValues.zip);
            signupData.append('credit_rep', signupInputValues.saleRep);
            signupData.append('resale_id', signupInputValues.resaleId);
            const params = {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            };
            axios.post(`${appConstant.API_URL}/user/signup`, signupData, params)
                .then(res => {
                    setIsLoading(false);
                    if (res?.data?.status === 200) {
                        setMyAccount(true);
                        localStorage.setItem("token", res?.data?.data?.token);
                        localStorage.setItem("full_name", res?.data?.data?.full_name);
                        localStorage.setItem("account_number", res?.data?.data?.account_number);
                        // localStorage.setItem("user_type", res?.data?.data?.user_type);
                        localStorage.setItem('cartTotal', 0);
                        window.location = window.location.href;
                        setResultMessage(res?.data?.message + 'Please close this window.');
                    }else {
                        setResultMessage(res?.data?.message);
                    }
                })
                .catch(error => {
                     setIsLoading(false);
                    // props.changeLogin(true);
                });
        }
    }
    // forgot password
    const [forgotInputValues, setForgotInputValues] = useState({
        email : ""
    });
    const handleForgotChange = (e) => {
        const {name, value} = e.target;
        setForgotInputValues({...forgotInputValues, [name]: value});
    }
    const [forgotValidation, setforgotValidation] = useState({
        email : ""
    });
    const checkForgotValidation = () => {
        let errors = JSON.parse(JSON.stringify(forgotValidation));

        // email validation
        const emailCond = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!forgotInputValues.email) {
          errors.email = "Email is required";
        } else if (!forgotInputValues.email.match(emailCond)) {
          errors.email = "Please enter valid email address";
        } else {
          errors.email = "";
        }
        setforgotValidation(errors);
        if(errors.email === ''){
          return true;
        }else{
          return false;
        }
    };
    const forgotPassword = (e) => {
        e.preventDefault();
        const isSuccess = checkForgotValidation();
        if(isSuccess) {
            setIsLoading(true);
            setLogin(false);
            setSignup(false);
            const forgotData = new FormData();
            forgotData.append('email', forgotInputValues.email);
            const params = {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            };
            axios.post(`${appConstant.API_URL}/user/forget_password`, forgotData, params)
            .then(res => {
              setIsLoading(false);
              if (res.data.status === 200) {
                  alert(res?.data?.message);
                  window.location.href = window.location;
                // setResultMessage(res?.data?.message);
                // setForgot(false);
                // setReset(true);
              }else {
                setResultMessage(res.data.error);
              }
            })
            .catch(error => {
              setIsLoading(false);
            });
        }
    }

    const checkRequest = () => {
        const checkRequestData = new FormData();
        checkRequestData.append('email', getProp.email);
        checkRequestData.append('otp', getProp.otp);
        const params = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        axios.post(`${appConstant.API_URL}/user/check_otp`, checkRequestData, params)
            .then(res => {
                setIsLoading(false);
                if (res?.data?.status === 200) {
                    setIsVerified(true);
                }else {
                    setIsVerified(false);
                    setCheckRequestMessage(res?.data?.message);
                }
            })
            .catch(error => {
                setIsVerified(false);
            });
    }
    const ResetPassword = () => {
        const checkRequestData = new FormData();
        checkRequestData.append('email', getProp.email);
        checkRequestData.append('otp', getProp.otp);
        checkRequestData.append('password', resetInputValues.npassword);
        const params = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        axios.post(`${appConstant.API_URL}/user/forget_otp`, checkRequestData, params)
            .then(res => {
                setIsLoading(false);
                if (res?.data?.status === 200) {
                    alert(res?.data?.message);
                    window.location.href = appConstant.SITE_URL;
                }else {
                    setResultMessage(res?.data?.message);
                }
            })
            .catch(error => {
                setIsVerified(false);
            });
    }
    // reset password
    const [resetInputValues, setResetInputValues] = useState({
        otp : "",
        npassword : "",
        cnpassword : ""
    });
    const handleresetChange = (e) => {
        const {name, value} = e.target;
        setResetInputValues({...resetInputValues, [name]: value});
    }
    const [resetValidation, setResetValidation] = useState({
        otp : "",
        npassword : "",
        cnpassword : ""
    });
    const checkresetValidation = () => {
        let errors = JSON.parse(JSON.stringify(resetValidation));

        //password validation
        const npassword = resetInputValues.npassword;
        const passReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6})/;
        if (!npassword) {
          errors.npassword = "New Password is required";
        }
        // else if (!npassword.match(passReg)) {
        //   errors.npassword = "Password must be at least 6 characters and must include at least one upper case letter, one lower case letter, one special character and one numeric digit.";
        // }
        else {
          errors.npassword = "";
        }
        //confirm password validation
        const cnpassword = resetInputValues.cnpassword;
        if (!cnpassword) {
        errors.cnpassword = 'Repeat New Password';
        } else if (cnpassword !== npassword) {
        errors.cnpassword = 'Both password should match';
        } else {
        errors.cnpassword = '';
        }
        setResetValidation(errors);
        if(errors.npassword === '' && errors.cnpassword === ''){
          return true;
        }else{
          return false;
        }
    };
    const resetPassword = (e) => {
        e.preventDefault();
        const isSuccess = checkresetValidation();
        if(isSuccess) {
            setIsLoading(true);
            ResetPassword();
            setLogin(true);
            setSignup(false);
            setForgot(false);
            setReset(false);
        }
    }
    const cartTotal = () => {
        const params = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        const cartData = new FormData();
        cartData.append('token', localStorage.getItem('token') ? localStorage.getItem('token') : '' );
        axios.post(`${appConstant.API_URL}/products/cart_total`, cartData, params)
            .then(res => {
                if(res?.data?.status === 200){
                    localStorage.setItem('cartTotal', res?.data?.data?.cart_total);
                }else{
                    localStorage.setItem('cartTotal', 0);
                }
            })
            .catch(error => {
            });
    }
    const brandsData = () => {
        const params = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        axios.get(`${appConstant.API_URL}/brands`, params)
            .then(res => {
                if(res?.data?.status === 200){
                    setBrands(res?.data?.data);
                }
            })
            .catch(error => {
            });
    }
    const brandCTA = (slug) => {
        window.location.href = '/brands/' + slug;
    }
    const categoryData = () => {
        const params = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        axios.get(`${appConstant.API_URL}/brands/category`, params)
            .then(res => {
                if(res?.data?.status === 200){
                    setCategory(res?.data?.data);
                }
            })
            .catch(error => {
            });
    }
    const bannerData = () => {
        const params = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        axios.get(`${appConstant.API_URL}/brands/banners`, params)
            .then(res => {
                if(res?.data?.status === 200){
                    setBanners(res?.data?.data);
                }
            })
            .catch(error => {
            });
    }
    const categoryCTA = (slug) => {
        window.location.href = '/category/' + slug;
    }
    const goToHome = () => {
        window.location.href = appConstant.SITE_URL;
    }
  return (
    <>
        <ScrollToTop />
        <header className="header-section border-0">
            <div id="tagline" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    {banners?.length > 0 && banners?.map((result, i) =>
                        <>
                    <div className={i === 0 ? "carousel-item active" : "carousel-item"}>
                        <h6 className='text-center'>{result?.title}</h6>
                    </div>
                    </>
                    )}
                </div>
                <button className="carousel-control-prev text-dark" type="button" data-bs-target="#tagline" data-bs-slide="prev">&#8249;</button>
                <button className="carousel-control-next text-dark" type="button" data-bs-target="#tagline" data-bs-slide="next">&#8250;</button>
            </div>
            <div className="header-top">
                <div className="ht-left">
                    <div className="mail-service">
                        <img src="/images/email-icon.png" alt="email"/>
                        <a href="mailto:accounts@sandsfashions.com">accounts@sandsfashions.com</a>
                    </div>
                    <div className="phone-service">
                        <img src="/images/mobile-icon.png" alt="email"/>
                        <a href="tel: +17183280001" > +1 718-328 0001</a>
                    </div>
                </div>
                <div className="ht-right">
                    <div className="top-social">
                        <a href="https://www.facebook.com/ssfashionsny/" target="_blank" rel="noreferrer"><img src="/images/facebook.png" alt="facebook"/></a>
                        <a href="https://twitter.com/Sands_Fashions" target="_blank" rel="noreferrer"><img src="/images/twitter.png" alt="twitter"/></a>
                        <a href="https://www.instagram.com/sandsfashions/" target="_blank" rel="noreferrer"><img src="/images/instagram.png" alt="instagram"/></a>
                        <a href="https://api.whatsapp.com/send?phone=13476735200&text=Hello" target="_blank" rel="noreferrer"><img src="/images/whatsapp.png" alt="whatsapp"/></a>
                        <a href="https://www.youtube.com/channel/UCunX_RavQ4zAWM9iIO2nqhw" target="_blank" rel="noreferrer"><img src="/images/youtube.png" alt="youtube"/></a>
                    </div>
                    <div className='catalog'>
                        <a href="https://issuu.com/sandsfashions/docs/catalog" target="_blank" rel="noreferrer">S & S Fashions Inc. Catalog</a>
                    </div>
                    <div className='catalog'>
                        <a href="https://issuu.com/sandsfashions/docs/ilny_catalog" target="_blank" rel="noreferrer">Fantastical Catalog</a>
                    </div>
                </div>
            </div>
        </header>    
        <section className="header-section sticky-top">
            <div className='mid-box'>
                <Link to="/"><img className='logo' src='/images/logo.png' alt='logo'/></Link>
                <form action="/shop">
                    <div className="search-box d-none d-md-flex">
                        <input className='search-input' type="text" placeholder="Search..." name="s"/>
                        <button type="submit" className="search"><img src="/images/search-black.png" alt="search"/></button>
                    </div>
                </form>
                <div>
                    {!myAccount ? <span className='mx-3'>
                    <Link to="/login" className="login-btn m-0">
                        Login
                    </Link> / <Link to="/signup" className="login-btn m-0">
                        Register
                    </Link>
                    </span>
                        : ''
                    }
                    {myAccount ? <>
                    <span className="dropdown">
                        <button className='dropdown-toggle login-btn' to="/" data-bs-toggle="dropdown" style={{paddingRight: '15px'}}><b>Hi {localStorage.getItem('full_name') ? localStorage.getItem('full_name') : 'Guest'} {localStorage.getItem('account_number') ? '(' + localStorage.getItem('account_number') + ')' : ''}</b></button>
                        <ul className="dropdown-menu" style={{minWidth: 'auto'}}>
                            <li><Link className="dropdown-item" to="/my-profile">My Profile</Link></li>
                            <li><Link className="dropdown-item" to="/orders">My Orders</Link></li>
                            <li><Link className="dropdown-item" to="/logout">Logout</Link></li>
                        </ul>
                    </span>                            
                        </>
                        : ''
                    }
                    <Link to='/cart' className='cart-btn'><img className='cart' src='/images/cart.png' alt='cart'/><span className='count'>{localStorage.getItem('cartTotal') ? localStorage.getItem('cartTotal') : 0 }</span></Link>
                </div>
            </div>
            <div className='last-box'>
                <nav className="navbar navbar-expand-md navbar-light w-100 p-0">
                    <div className="search-box d-md-none d-flex">
                        <form action="/shop" className='w-100 d-flex'>
                            <input className='search-input' type="text" placeholder="Search..." name="s"/>
                            <button type="submit" className="search"><img src="/images/search-black.png" alt="search"/></button>
                        </form>
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse w-100" id="collapsibleNavbar">
                        <ul className="navbar-nav w-100 justify-content-center">
                            <li className="nav-item">
                                <Link className={"nav-link " + (props.id === "1" ? 'active' : '')} to="/">Home</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className={"nav-link dropdown-toggle " + (props.id === "2" ? 'active' : '')} to="/" role="button" data-bs-toggle="dropdown">Brands</Link>
                                <ul className="dropdown-menu">
                                    {brands?.length > 0 && brands?.map((result, i) =>
                                        <>
                                    <li key={'brands' + i}><div className="dropdown-item" onClick={() => brandCTA(result?.slug)}>{result?.brand_actual_name}</div></li>
                                        </>
                                    )}
                                </ul>
                            </li>
                            <li className="nav-item">
                                <Link className={"nav-link " + (props.id === "3" ? 'active' : '')} to="/shop">Shop</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className={"nav-link dropdown-toggle " + (props.id === "4" ? 'active' : '')} to="/" role="button" data-bs-toggle="dropdown">Categories</Link>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    {category?.length > 0 && category?.map((result, i) =>
                                        <>
                                            <li key={'category' + i}><div className="dropdown-item" onClick={() => categoryCTA(result?.slug)}>{result?.category_actual_name}</div></li>
                                        </>
                                    )}
                                </ul>
                            </li>
                            <li className="nav-item">
                                <Link className={"nav-link " + (props.id === "5" ? 'active' : '')} to="/popular">Popular</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={"nav-link " + (props.id === "6" ? 'active' : '')} to="/about-us">About Us</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={"nav-link " + (props.id === "7" ? 'active' : '')} to="/contact-us">Contact</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link pb-0" to="/category/nyccollection"><img src="/images/ilny.jpg" alt="ilny"/></Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </section>
        {/* <div className='header-space'></div> */}
        {/* login popup  */}
        <div className="modal back-shadow" id="login" style={{display : showResetPasswordBox ? 'block' : 'none' }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    {!showResetPasswordBox && <button type="button" className="close" data-bs-dismiss="modal">&times;</button>}
                    <div className="modal-body">
                    {login ? <>
                        <h4 className='title'>Login</h4>
                            {resultMessage && <p className='formErrors mb-2'>{resultMessage}</p>}
                        <form className='login-form' onSubmit={(e) => {logIn(e)}}>
                            <label>Email:</label>
                            <input type="text" placeholder="Enter Email" name='email' value={loginInputValues.email} onChange={(e) => handleLoginChange(e)}/>
                            {loginValidation.email && <p className='formErrors'>{loginValidation.email}</p>}
                            <label>Password:</label>
                            <div className='position-relative'>
                            <input type={passwordShown ? "text" : "password"} placeholder="Enter Password" name='password' value={loginInputValues.password} onChange={(e) => handleLoginChange(e)}/>
                            <img src={passwordShown ? "/images/eyeshow.png" : "/images/eyehide.png"} className="password-hide" alt="password" onClick={togglePassword}/>
                            </div>
                            {loginValidation.password && <p className='formErrors'>{loginValidation.password}</p>}
                            <label className="d-flex align-items-center justify-content-between my-4">
                                <div className="form-check form-switch mb-0">
                                    <input className="form-check-input mb-0" type="checkbox" name="hide-billing" checked={checked} onChange={e => setChecked(e.target.checked)}/>
                                    <label className='ms-3 mb-0'>Remember me</label>
                                </div>
                                <span className='forget-link links' onClick={showForgot}>Forgot Password</span>
                            </label>
                            {isLoading ? <div className='text-center mb-4'><button type='submit' className="buttons">Login <img className='ms-2' src='/images/default.gif' width={'16px'} height={'16px'} alt={'default'}/></button></div> :
                               <div className='text-center mb-4'><button type='submit' className="buttons">Login</button></div>
                            }

                            <h6 className='tagline'>Don't have account? <button className='links' onClick={showSignup}>Signup</button></h6>
                        </form>
                        </>
                        : ''
                    }
                    {signup ? <>
                        <h4 className='title'>Signup</h4>
                            {resultMessage && <p className='formErrors mb-2'>{resultMessage}</p>}
                        <form className='signup-form' onSubmit={signUp}>
                            <label>First Name:</label>
                            <input type="text" placeholder="First Name" name="fname" value={signupInputValues.fname} onChange={(e) => handleSignupChange(e)}/>
                            {signupValidation.fname && <p className='formErrors'>{signupValidation.fname}</p>}
                            <label>Last Name:</label>
                            <input type="text" placeholder="Last Name" name="lname" value={signupInputValues.lname} onChange={(e) => handleSignupChange(e)}/>
                            {signupValidation.lname && <p className='formErrors'>{signupValidation.lname}</p>}
                            <label>Mobile Number:</label>
                            <input type="text" placeholder="Mobile Number" name="number" value={signupInputValues.number} onChange={(e) => handleSignupChange(e)}/>
                            {signupValidation.number && <p className='formErrors'>{signupValidation.number}</p>}
                            <label>Email:</label>
                            <input type="text" placeholder="Email" name="email" value={signupInputValues.email} onChange={(e) => handleSignupChange(e)}/>
                            {signupValidation.email && <p className='formErrors'>{signupValidation.email}</p>}
                            <label>Password:</label>
                            <div className='position-relative'>
                            <input type={passwordShown ? "text" : "password"} placeholder="Password" name="password" value={signupInputValues.password} onChange={(e) => handleSignupChange(e)}/>
                            <img src={passwordShown ? "/images/eyeshow.png" : "/images/eyehide.png"} className="password-hide" alt="password" onClick={togglePassword}/>
                            </div>
                            {signupValidation.password && <p className='formErrors'>{signupValidation.password}</p>}
                            <label>Confirm Password:</label>
                            <div className='position-relative'>
                            <input type={confirmPasswordShown ? "text" : "password"} placeholder="Confirm Password" name="cpassword" value={signupInputValues.cpassword} onChange={(e) => handleSignupChange(e)}/>
                            <img src={confirmPasswordShown ? "/images/eyeshow.png" : "/images/eyehide.png"} className="password-hide" alt="password" onClick={toggleConfirmPassword}/>
                            </div>
                            {signupValidation.cpassword && <p className='formErrors'>{signupValidation.cpassword}</p>}
                            <label>Company:</label>
                            <input type="text" placeholder='Enter Company Name' name='company'  value={signupInputValues.company} onChange={(e) => handleSignupChange(e)}/>
                            <label>Address:</label>
                            <input type='text' name='address' placeholder='Address' value={signupInputValues.address} onChange={(e) => handleSignupChange(e)}/>
                            {signupValidation.address && <p className='formErrors'>{signupValidation.address}</p>}
                            <label>City</label>
                            <input type="text" placeholder='Enter City' name='city' value={signupInputValues.city} onChange={(e) => handleSignupChange(e)}/>
                            {signupValidation.city && <p className='formErrors'>{signupValidation.city}</p>}
                            <label>State:</label>
                            <select name="state" value={signupInputValues.state} onChange={(e) => handleSignupChange(e)}>
                                <option value="">Select State</option>
                              {appConstant.STATES.length > 0  && appConstant.STATES?.map((result, i) =>
                                  <option value={result} key={i}>{result}</option>
                              )}
                            </select>
                            {signupValidation.state && <p className='formErrors'>{signupValidation.state}</p>}
                            <label>Country</label>
                            <select name="country"  value={signupInputValues.country} onChange={(e) => handleSignupChange(e)}>
                                <option value="">Select Country</option>
                                <option value="USA">USA</option>
                            </select>
                            {signupValidation.country && <p className='formErrors'>{signupValidation.country}</p>}
                            <label>Zip/Postal Code</label>
                            <input type="text" placeholder='Enter Zip/Postal Code' name='zip'  value={signupInputValues.zip} onChange={(e) => handleSignupChange(e)}/>
                            {signupValidation.zip && <p className='formErrors'>{signupValidation.zip}</p>}
                            <label>Sales Rep (Optional)</label>
                            <select name="saleRep"  value={signupInputValues.saleRep} onChange={(e) => handleSignupChange(e)}>
                                <option value="">Select Sales Rep</option>
                                <option value="1">1</option>
                            </select>
                            <label>Resale ID (Optional)</label>
                            <input type='text' placeholder='Enter Resale Id' name='resaleId'  value={signupInputValues.resaleId} onChange={(e) => handleSignupChange(e)}/>
                            {isLoading ? <div className='text-center mb-4'><button type='submit' className="buttons">Signup <img className='ms-2' src='/images/default.gif' width={'16px'} height={'16px'} alt={'signup'}/></button></div> :
                               <div className='text-center mb-4'><button type='submit' className="buttons">Signup</button></div>
                            }
                            <h6 className='tagline'>Have account? <button className='links' onClick={showLogin}>Login</button></h6>
                        </form>
                        </>
                        : ''
                    }
                    {forgot ? <>
                        <h4 className='title'>Forgot Password</h4>
                        {resultMessage && <p className='formErrors mb-2'>{resultMessage}</p>}
                        <form className='forgot-form' onSubmit={forgotPassword}>
                            <label>Email:</label>
                            <input type="text" placeholder="Enter Email" name="email" value={forgotInputValues.email} onChange={(e) => handleForgotChange(e)}/>
                            {forgotValidation.email && <p className='formErrors'>{forgotValidation.email}</p>}
                            {isLoading ? <div className='text-center mb-4'><button type='submit' className="buttons">Submit <img className='ms-2' src='/images/default.gif' width={'16px'} height={'16px'}/></button></div> :
                               <div className='text-center mb-4'><button type='submit' className="buttons">Submit</button></div>
                            }
                            <h6 className='tagline mb-2'>OR</h6>
                            <h6 className='tagline'><button className='links' onClick={showLogin}>Login</button> <button className='links' onClick={showSignup}>Signup</button></h6>
                        </form>
                        </>
                        : ''
                    }
                    {reset ? <>

                    {isVerified ? <> <h4 className='title'>Reset Password</h4>
                        {resultMessage && <p className='formErrors mb-2'>{resultMessage}</p>}
                        <form className='reset-form' onSubmit={resetPassword}>
                            <label>New Password:</label>
                            <div className='position-relative'>
                            <input type={passwordShown ? "text" : "password"} placeholder="Password" name="npassword" value={resetInputValues.npassword} onChange={(e) => handleresetChange(e)}/>
                            <img src={passwordShown ? "/images/eyeshow.png" : "/images/eyehide.png"} className="password-hide" alt="password" onClick={togglePassword}/>
                            </div>
                            {resetValidation.npassword && <p className='formErrors'>{resetValidation.npassword}</p>}
                            <label>Confirm New Password:</label>
                            <div className='position-relative'>
                            <input type={confirmPasswordShown ? "text" : "password"} placeholder="Confirm Password" name="cnpassword" value={resetInputValues.cnpassword} onChange={(e) => handleresetChange(e)}/>
                            <img src={confirmPasswordShown ? "/images/eyeshow.png" : "/images/eyehide.png"} className="password-hide" alt="password" onClick={toggleConfirmPassword}/>
                            </div>
                            {resetValidation.cnpassword && <p className='formErrors'>{resetValidation.cnpassword}</p>}
                            {isLoading ? <div className='text-center mb-4'><button type='submit' className="buttons">Change Password <img className='ms-2' src='/images/default.gif' width={'16px'} height={'16px'}/></button></div> :
                               <div className='text-center mb-4'><button type='submit' className="buttons">Change Password</button></div>
                            }
                            <h6 className='tagline mb-2'>OR</h6>
                            <h6 className='tagline'><button className='links' onClick={showLogin}>Login</button> <button className='links' onClick={showSignup}>Signup</button></h6>
                        </form>
                        </> : <> <h4 className='mb-4 text-center' style={{color: 'red'}}>{checkRequestMessage}</h4>
                                <button className='buttons d-block m-auto' data-bs-dismiss="modal" onClick={() => {goToHome()}}>Go to Home</button> </>}
                        </>
                        : ''
                    }
                    </div>
                </div>
            </div>
        </div>
    </>
  )
};

export default Header;
