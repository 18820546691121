import React from 'react';

function LoadingSpinner() {
    return (
        <div className="spinner-container">
            <img src='/images/default.gif' width={'100px'} height={'100px'}/>
        </div>
    );
}
export default LoadingSpinner;
