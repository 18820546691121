import React, {useEffect, useState} from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Sidebar from './shared/Sidebar';
import { Link } from 'react-router-dom';
import axios from 'axios';
import appConstant from './shared/constant/constant.json';
import LoadingSpinner from './shared/Loader';
function AddCard() {
  return (
    <>
        {/* header  */}
        <Header/>
        <div className='cart-section page-spacing'>
          <div className='breadcrumbs'>
              <Link to="/">Home / </Link> <strong>Add New Card</strong>
          </div>
          <div className='row'>
            <div className='col-md-3'>
                <div className='card'>
                  <div className="card-body">
                      <h6 className='title'>MY ACCOUNT</h6>
                      <Sidebar id='4'/>
                  </div>
                </div>
            </div>
            <div className='col-md-9'>
                <h6 className='title mb-4'>Add New Card</h6>
                <form id="payment-form">
                    <div id="card-container"></div>
                    <div className='d-flex mt-4 justify-content-center'> <button type='button' id="card-button" className='buttons'>Add Card</button></div>
                </form>
                <div id="payment-status-container"></div>              
            </div>
          </div>
        </div>
        {/* footer  */}
        <Footer/>
    </>
  )
};
export default AddCard;