import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import Header from './shared/Header';
import Footer from './shared/Footer';
import axios from "axios";
import appConstant from "./shared/constant/constant.json";
import LoadingSpinner from './shared/Loader';
function Cart() {
  const [isLoading, setIsLoading] = useState(true);
  const [cartItems, setCartItems] = React.useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  const [isLoadingbutton, setIsLoadingButton] = useState(false);
  const [skus, setSkus] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [inputValues, setInputValue] = useState({
    quantity: "1"
  });
  useEffect(() => {
    cart();
    cartTotal();
  }, []);
  function handleChange(e, i, m) {
    const { name, value } = e.target;
    let data1 = [...cartItems];
    data1[i][m].quantity = e.target.value;
    setCartItems(data1);
      let data = [...skus];
      for(let i = 0; i<cartItems.length; i++) {
          if(cartItems[i].length > 0) {
              let j = 0;
              for (let m=0; m<cartItems[i].length; m++){
                  if(parseInt(cartItems[i][m].quantity) > 0){
                      let element = {};
                      element.sku = cartItems[i][m].sku;
                      element.quantity = cartItems[i][m].quantity;
                      data[j] = element;
                      j++;
                  }
              }
          }
      }
      setSkus(data);
  }
  const cart = () => {
    const params = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    const cartData = new FormData();
    let url = '';
    if(localStorage.getItem('token')){
        url = `${appConstant.API_URL}/products/cart`;
        cartData.append('token', localStorage.getItem('token') ? localStorage.getItem('token') : '' );
    }else{
        url = `${appConstant.API_URL}/products/cart_without_token`;
        if(localStorage.getItem('cartItems')){
              cartData.append('skuString', localStorage.getItem('cartItems') );
        }
    }    
    axios.post(url, cartData, params)
        .then(res => {
          setIsLoading(false);
          if(res?.data?.status === 200){
            setCartItems(res?.data?.data);
            setTotalPrice(res?.data?.total_price);
          }else{
              setCartItems([]);
          }
        })
        .catch(error => {
          setIsLoadingButton(false);
        });
        
  }
  const removeItem = (sku) => {
    if(localStorage.getItem('token')){
        const params = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
        }
        const cartData = new FormData();
        cartData.append('token', localStorage.getItem('token') ? localStorage.getItem('token') : '' );
        cartData.append('sku', sku);
        axios.post(`${appConstant.API_URL}/products/delete_cart`, cartData, params)
            .then(res => {
            if(res?.data?.status === 200){
                cart();
            }else{
                setCartItems([]);
            }
            })
            .catch(error => {
            });
    }else{
       localStorage.removeItem('cartTotal');
       localStorage.removeItem('cartItems');
       setCartItems([]);
    }
  }
  const updateCart = () => {
    const cartData = new FormData();
    if(localStorage.getItem('token')){
        setIsLoadingButton(true);
        const params = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        cartData.append('token', localStorage.getItem('token') ? localStorage.getItem('token') : '' );
        cartData.append('cart_items', JSON.stringify(skus));
        axios.post(`${appConstant.API_URL}/products/add_to_cart`, cartData, params)
            .then(res => {
                setIsLoadingButton(false);
                if(res?.data?.status === 200){
                    cart();
                }
            })
            .catch(error => {
                setIsLoadingButton(false);
            });
    }else{
        setIsLoadingButton(true);
        const allCartItems = cartItems;
        const newData = [];
        for(let item of allCartItems){
            for(let data of item){
                const newCartItem = {
                    sku: data.sku,
                    quantity: data.quantity
                }
                newData.push(newCartItem);
            }
        }
        localStorage.setItem('cartItems', JSON.stringify(newData))
        const params = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          }
       const cartData = new FormData();
       cartData.append('skuString', JSON.stringify(newData));
       axios.post(`${appConstant.API_URL}/products/cart_without_token`, cartData, params)
        .then(res => {
        setIsLoadingButton(false);
          if(res?.data?.status === 200){
            setCartItems(res?.data?.data);
            setTotalPrice(res?.data?.total_price);
            cartTotal();
          }else{
              setCartItems([]);
          }
        })
        .catch(error => {
          setIsLoadingButton(false);
          
        });
    }
  }
  const cartTotal = () => {
    if(localStorage.getItem('token')){
        const params = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        const cartData = new FormData();
        cartData.append('token', localStorage.getItem('token') ? localStorage.getItem('token') : '' );
        axios.post(`${appConstant.API_URL}/products/cart_total`, cartData, params)
            .then(res => {
                if(res?.data?.status === 200){
                    localStorage.setItem('cartTotal', res?.data?.data?.cart_total);
                }else{
                    localStorage.setItem('cartTotal', 0);
                }
            })
            .catch(error => {
            });
    }else{
        if(localStorage.getItem('cartItems')){
            const data = JSON.parse(localStorage.getItem('cartItems'));
            const NewData = data.reduce((total, item) => total + parseInt(item.quantity), 0);
            localStorage.setItem('cartTotal', NewData);
        }else{
            localStorage.setItem('cartTotal', 0);
        }
    }
}
    const CTA = () => {
        window.location.href = '/checkout';
    }
  return (
    <>
    <Header/>
    <div className="cart-section page-spacing">
        <div className='breadcrumbs'>
            <Link to="/">Home / </Link> <Link to="/shop">Shop / </Link>  <strong>Cart</strong>
        </div>
        {isLoading ? <LoadingSpinner/> :
        <>
        {cartItems?.length > 0 && <>
            <div className='table-responsive mb-4'>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th className='first-child'>ITEM(S)</th>
                        <th>STYLE NAME</th>
                        <th>BRAND NAME</th>
                        <th>COLORS</th>
                        <th>SIZE</th>
                        <th>PRICE</th>
                        <th>QTY</th>
                        <th>SUB TOTAL</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {cartItems?.length > 0 && cartItems?.map((result, i) =>
                        <>
                            {result?.length > 0 && result?.map((result1, m) =>
                                <>
                                    {m === 0 && <tr key={m + 'cartItems'}>
                                        <td rowSpan={result?.length} className='first-child'>
                                            <img className='product-image me-2' src={appConstant.CDN_URL + result1.colorFrontImage}
                                                 alt="product"/>
                                            {/* <h6 className='product-name mt-2'>Product Name</h6> */}
                                        </td>
                                        <td rowSpan={result?.length}>{result1?.styleName}</td>
                                        <td rowSpan={result?.length}>{result1?.brandName}</td>
                                        <td>{result1?.colorName}</td>
                                        <td>{result1?.sizeName}</td>
                                        <td>${result1?.user_price}</td>
                                        <td><input className='mx-auto mb-0' name='quantity' type='number' min='1'
                                                   value={result1?.quantity}
                                                   onChange={(e) => handleChange(e, i, m)}/></td>
                                        <td>${result1?.sub_total}</td>
                                        <td>
                                            <button type="button" className='buttons mini-buttons my-1' onClick={() => {
                                                removeItem(result1?.sku)
                                            }}>Remove
                                            </button>
                                        </td>
                                    </tr>
                                    }
                                    {m > 0 && <tr>
                                        <td>{result1?.colorName}</td>
                                        <td>{result1?.sizeName}</td>
                                        <td>${result1?.user_price}</td>
                                        <td><input className='mx-auto mb-0' name='quantity' type='number' min='1'
                                                   value={result1?.quantity}
                                                   onChange={(e) => handleChange(e, i, m)}/></td>
                                        <td>${result1?.sub_total}</td>
                                        <td>
                                            <button type="button" className='buttons mini-buttons my-1' onClick={() => {
                                                removeItem(result1?.sku)
                                            }}>Remove
                                            </button>
                                        </td>
                                    </tr>
                                    }
                                </>
                            )}
                        </>
                    )}
                    <tr>
                        <th colSpan={7} className='text-end'><big>Total</big></th>
                        <td>${totalPrice}</td>
                        <td><button type="button" className='buttons mini-buttons m-0 mt-2' onClick={() => {removeItem('all')}}>Clear Cart</button></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className='d-flex justify-content-between align-items-center'>
                <Link to='/shop' className="buttons">&#129052; Back to Shopping</Link>
                <div className='buttons' onClick={() => CTA()}>Proceed to Checkout <img className='ms-1'
                                                                                  src="/images/checkout.png"
                                                                                  width="24px" alt="product"/></div>
                {isLoadingbutton ? <button style={{minWidth: '200px'}} className='buttons' onClick={() => {
                        updateCart()
                    }}>Update Cart <img className='ms-1' src="/images/update-cart.png" width="24px" alt="product"/> <img
                        className='ms-2' src='/images/default.gif' width={'16px'} height={'16px'} alt={'default'}/>
                    </button> :
                    <button style={{minWidth: '200px'}} className='buttons' onClick={() => {
                        updateCart()
                    }}>Update Cart <img className='ms-1' src="/images/update-cart.png" width="24px" alt="product"/>
                    </button>
                }
            </div>
        </>
        }
        {cartItems?.length === 0 && <><hr/> <h5 className="text-center">Cart is empty.</h5> <hr/></> }
        </>
        }
    </div>
    <Footer/>
    </>
  )
};

export default Cart;