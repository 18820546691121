import React from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { Link } from 'react-router-dom';

function PrivacyPolicy() {
  return (
    <>
        {/* header  */}
        <Header/>
        <div className='static-page-banner'></div>
        <div className='static-page-content page-spacing mb-4'>          
          <div className='breadcrumbs'>
            <Link to="/">Home / </Link> <strong>Privacy Policy</strong>
          </div>
          <h6 className='heading'>Privacy Policy</h6>
          <h6 className='title'>Acceptance of Terms</h6>
          <p className='content'>Welcome to www.sandsfashions.com (the “<strong>Site</strong>”), a web site operated on behalf of S & S Fashions, Inc. (“<strong>S&S</strong>”, “<strong>us</strong>” and “<strong>our</strong>”). Your privacy is important to us. It is our policy to protect your Personal Information (as defined below) and to use it only in accordance with this Privacy Policy. Using our Site is voluntary, and by using the Site, you consent to our collection and use of your identifying and non-identifying information as described in this Privacy Policy. This Privacy Policy only governs our use and disclosure of information collected through this Site.</p>
          <h6 className='title'>Information We Collect From You</h6>
          <p className='content'>We automatically collect information about your Internet connection, including but not limited to:</p>
          <ul className='static-page-list'>
            <li>The name of the Internet domain you used to access the Internet;</li>
            <li>The IP address of the web site from which you linked directly to our Site;</li>
            <li>The connection speed/screen resolution/browser used;</li>
            <li>The date and time you accessed our Site; and</li>
            <li>The pages you visited.</li>
          </ul>
          <p className='content'>This information (“<strong>Non-Personal Information</strong>”) does not identify you personally. We use this Non-Personal Information to monitor the effectiveness of the Site and to consider potential improvements to the Site.</p>
          <p className='content'>We may request that you provide certain personally identifiable information or information specific to your business (“<strong>Personal Information</strong>”) to view certain areas of the Site and/or purchase products from the Site. We request that you provide (1) contact information, including but not limited to your name, address, e-mail address and phone number and (2) certain credit card information when making a purchase on the Site. <strong>Your credit card information is encrypted and provided to the credit card issuer for verification and approval. We do not retain any credit card information At your option, you may download a credit application, fill it out and submit through the Site.</strong> We do not collect any Personal Information about you when you visit the Site unless you give us that information. You may view the products on the Site without registering or providing any Personal Information. You may choose not to provide Personal Information via the Site by not purchasing any products available on the Site.</p>
          <h6 className='title'>Use of Your Personal Information</h6>
          <p className='content'>We use your Personal Information to administer your participation in the Site and to communicate information we think may be of interest to you.</p>
          <h6 className='title'>Sharing Your Information</h6>
          <p className='content'>We may use third party service providers and suppliers to facilitate the Site and the services we provide, and they may have access to Personal Information. In addition, we may buy or sell business assets or be acquired by or acquire other corporations. In such transactions, Personal Information generally is one of the transferred business assets. In addition, in the event we merge or consolidate with another institution, Personal Information may be transferred to the successor entity. We may also disclose Personal Information to other affiliated institutions and/or if we are required to do so by law.</p>
          <h6 className='title'>Use of Cookies</h6>
          <p className='content'>The use of cookies enables us to enhance your visits to the Site. Cookies are alphanumeric identifiers that are transferred to your computer's hard drive through your web browser. The help portion of the toolbar on most browsers will tell you how to prevent your browser from accepting new cookies, but this may limit your ability to use some features of the Site. Cookies enable us to recognize your browser and provide features that are tailored to your needs. Cookies must be enabled in order for you to log onto the Site. We use them to keep track of your user name and password, but this feature is optional and you may elect not to have your user name and password recognized. </p>
          <p className='content'>We may use information from cookies for purposes of planning and tracking, as well as assessing the technical function of the Site. We may also use cookies to track information about which pages you visit within the Site to help us gauge the effectiveness of the Site or our content.</p>
          <h6 className='title'>Linked Sites</h6>
          <p className='content'>We may provide links on the Site to other sites of interest. We do not review or endorse the content of these sites or guarantee that they will abide by this Privacy Policy. Your use of such linked sites is subject to the privacy policies of the providers of those sites. We encourage our users to be aware when they leave our Site and to read the privacy statements of each Site that collects Personal Information.</p>
          <h6 className='title'>Security Measures</h6>
          <p className='content'>We work hard to protect the security of your information. Our registration pages of our Site use Secure Socket Layer (“SSL”) technology, the current standard for secure transactions over the Internet. While we strive to protect your information by using SSL and through measures to protect our system, such security cannot be guaranteed. Consequently, we do not insure or warrant the security of information you transmit, and you do so at your own risk. Passwords are encrypted and cannot be looked up by anyone.</p>
          <h6 className='title'>Children and Parents</h6>
          <p className='content'>We do not offer or provide products and services for children. If you are under 18, you may not use our Site. We do not intend to collect Personal Information from children. If your child has submitted Personal Information and you would like to request that such information be removed, you may contact us via one of the contact methods described on the Site and we will promptly remove such information and shall not use such information or include it in our database. Users under the age of 18 and their parents or guardians are advised that any Personal Information voluntarily entered by children may be available for public access until we become aware that a user is under the age of 18 and have had a reasonable opportunity to remove the information from the site.</p>
          <h6 className='title'>Reviewing Information</h6>
          <p className='content'>You may review your Personal Information stored in your profile by logging into the Site. You may change your email address or the designated contact person but if you wish to make a change to your company name or address, you will need to contact our Customer Service Department at 630-679-9940. You may contact us via one of the contact methods described in the Terms and Conditions if you wish to discuss any issues regarding your Personal Information.</p>
          <h6 className='title'>Changes</h6>
          <p className='content'>We may change this Privacy Policy from time-to-time. If we do make a change, we will post the revised Privacy Policy on this Site. We encourage you to review this Privacy Policy periodically. Questions, comments or complaints about our privacy policies should be submitted to us via the methods described in the Terms and Conditions.</p>
        </div>
        {/* footer  */}
        <Footer/>
    </>
  )
};
export default PrivacyPolicy;