import React from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { Link } from 'react-router-dom';

function OrderFailed() {
  return (
    <>
        {/* header  */}
        <Header/>
        <div className='static-page-banner'></div>
        <div className='static-page-content page-spacing mb-4'>          
          <div className='breadcrumbs'>
              <Link to="/">Home / </Link><Link to="/orders">Orders / </Link> <strong>Order Failed</strong>
          </div>
          <div className='order-status'>
            <div className='row d-flex align-items-center'>
                <div className='col-md-6 mb-4'>
                  <img className='order-success' src='/images/order-failed.png' alt='order-success'/>
                </div>
                <div className='col-md-6'>
                  <h6 className='heading'>Order Failed</h6>
                  <p className='content mb-4'>Your payment method declined, please try again or call us at <a className='links' href="tel: +12122900009" >+1 212-290 0009</a></p>  
                  <Link to='/shop' className="buttons">&#129052; Back to Shopping</Link>
                </div>
            </div>            
          </div> 
        </div>
        {/* footer  */}
        <Footer/>
    </>
  )
};
export default OrderFailed;