import React from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { Link } from 'react-router-dom';

function TermsConditions() {
  return (
    <>
        {/* header  */}
        <Header/>
        <div className='static-page-banner'></div>
        <div className='static-page-content page-spacing mb-4'>          
          <div className='breadcrumbs'>
            <Link to="/">Home / </Link> <strong>Terms and Conditions</strong>
          </div>
          <h6 className='heading'>Terms and Conditions</h6>          
          <p className='content'>Orders for S & S Fashions, Inc. (<strong>S&S”</strong>“) goods (the <strong>“Goods”</strong>) are subject to the following terms and conditions of sale (the <strong>“Sale Agreement”</strong>). This Sale Agreement shall govern the sale of Goods from S&S to you and/or the company you are authorized to represent (“you”). By issuing a purchase order for Goods from S&S, you accept and agree to the terms of this Sale Agreement. S&S's performance is expressly made conditional upon your agreement to the terms of this Sale Agreement. Any provisions or conditions of any purchase order or other document, which are inconsistent with or in addition to the terms of this Sale Agreement are hereby rejected and shall be inapplicable and not binding upon S&S.</p>
          <h6 className='title'>ORDERING</h6>
          <p className='content'>To enable you to place an order with S&S to purchase its Goods through the S&S web site, you are required to have first registered with S&S by phone or through the S&S web site to be an approved on-line buyer. S&S shall make commercially reasonable efforts to meet any shipment date stated on the face of any accepted order. However, you understand and agree that shipment of Goods is subject to availability, and S&S EXPRESSLY DISCLAIMS LIABILITY FOR ANY FAILURE TO MEET SUCH DELIVERY DATES. S&S shall have the right to deliver Goods at one time or in portions from time to time, and to invoice for those portions delivered. Payment will not be considered final until all shipping and/or freight charges and taxes billed to you have been paid.</p>
          <h6 className='title'>PAYMENT</h6>
          <p className='content'>You shall purchase Goods in accordance with S&S's price list in effect at the time of the order (less other discounts, if any). Prices are exclusive of all sales or use taxes, tariffs, customs (except fees associated with original U.S. importation which S&S pays), duties and other governmental charges. You shall pay or reimburse S&S for any and all such charges. Late payment of any amount will be grounds for S&S to discontinue performance under this Sale Agreement. Any amounts not paid by the due date will be subject to a finance charge at a rate equal to the lesser of 1.5% per month or the maximum rate allowed by law. However, payment of such finance charge will not excuse or cure a breach or default for late payment. Returned checks will be subject to a $40.00 per check charge. You remain responsible for all shipping and handling charges including, without limitation, failure by the consignee to pay shipping charges, failure by any third party to pay shipping charges, or an incorrect or invalid shipping account number. S&S accepts American Express, Discover, Visa and MasterCard (please ask your sales representative for details). If you have any questions regarding payment terms, contact our credit department at 718-328 0001</p>
          <ul className='static-page-list'>
            <li><strong>COD Cashiers Check - </strong> All initial orders are shipped on a COD Cashiers Check basis. Payment must be made to the carrier in the form of a cashiers check or money order. Personal checks will not be accepted.</li>
            <li><strong>COD Check - </strong> Upon approval, customers may make payment to the carrier by company check. To obtain company check privileges, please request an application from your salesperson or CSR. Personal checks will not be accepted.</li>
            <li><strong>Net Terms - </strong> Open terms are granted to customers with excellent credit references. Financial statements may also be required. Open terms are net from date of invoice. A finance charge of 1.5% per month (12% per annum) will be charged on balances beyond established terms. We reserve the right to revoke credit terms.</li>
          </ul>    
          <h6 className='title'>SHIPPING/PICK-UP</h6>
          <p className='content'>Orders placed before 4:00 pm (CT) will be shipped the same day. Orders received after 4:00 pm will be shipped the following day. Truck shipments will be shipped the same day for orders taken before 3:00 pm. Shipments will include shipping and handling. Expedited shipments are available upon request. S&S will not be responsible for any delay in the delivery or shipment of goods, or for any damages suffered by reason of such delay. All shipments are F.O.B. from original ship point. You may pick up your order between 8:00 am and 5:00 pm Monday through Friday at the S&S facility. Please allow 2 hours for processing order. Orders placed after 3:00 pm will be ready at 10:00 am the following morning.</p>
          <h6 className='title'>PRICING</h6>
          <p className='content'>All prices are subject to change without notice. S&S assumes no responsibility and shall incur no liability whatsoever if price change notices are not received. Customers will be charged the prices that are in effect at the time merchandise is shipped. In the event of new federal or state taxes or legislation affecting the costs of products or items, S&S reserves the right to increase prices as it deems appropriate or necessary. Merchandise is subject to change or withdrawal, or may be temporarily or permanently out of stock. We assume no liability for delays or failure to deliver due to our inability to obtain supplies. Please call for current pricing when ordering basic t-shirts to learn about our competitive pricing. For businesses, we require a signed State Tax Exemption Certificate on file before shipping your first order.</p>
          <ul className='static-page-list'>
            <li><strong>Case price - </strong>Price per piece of the same style, color, and size when ordering case quantity.</li>
          </ul>
          <h6 className='title'>RETURNS AND CANCELLED ORDERS</h6>
          <p className='content'>It is your responsibility to check immediately for discrepancies or defects in an order before any alterations or embellishments are made. If you have a problem or discrepancy with an order, contact our Help Desk at 718-328 0001within 5 days after date of shipment. Returns must be authorized by S&S in advance</p>
          <ul className='static-page-list'>
            <li>S&S will absolutely not accept returns of printed, washed or decorated merchandise. You are responsible for inspecting the garments before performing any printing.</li>
            <li>All claims for shortages, damages, etc., must be made within 72 hours of receipt of merchandise.</li>
            <li>All returned or refused shipments are subject to a 20% or $25 (whichever is greater) restocking charge in addition to both outbound freight charges, return freight charges and any COD charges.</li>
            <li>No returns will be accepted by our warehouse without a valid return authorization number.</li>
            <li>Defective merchandise must be returned to us with a piece of tape indicating the location of the flaw.</li>
            <li>Merchandise over 30 days old and discontinued styles cannot be returned.</li>
            <li>Please include a copy of the invoice to insure proper credit in a timely manner.</li>
          </ul>
          <h6 className='title'>EXPORT SHIPMENTS</h6>
          <p className='content'>Many Goods that have been imported into the U.S. cannot be exported to other countries. It is your responsibility to check with your freight forwarder to confirm foreign documentation requirements and verify that items to be exported will be released by customs at the final destination. S&S is not responsible for providing this information or any documentation required to export goods purchased from S&S or for re-importation to the U.S.</p>
          <h6 className='title'>PRODUCT SAFETY</h6>
          <p className='content'>The Consumer Product Safety Improvement Act (“CPSIA”) became law on August 14, 2008 and is applicable to consumer products (including textile wearables and non-wearables) manufactured on and after November 12, 2008. The CPSIA requires all importers and domestic manufacturers of apparel and other applicable non-wearable consumer products to issue Certificate of Conformity (“COC”) for every lot manufactured on or after November 12, 2008. It is important to note that both imported and domestically made products will require COC's. Please contact the appropriate manufacturer of the Goods that you are purchasing for information regarding their COC and product safety warnings and warranties.</p>
          <h6 className='title'>WARRANTIES AND DISCLAIMER</h6>
          <p className='content'>S&S warrants only that the Goods shall be free from material defects as of the delivery date, provided, you store and handle the delivered Goods in such a manner that meets or exceeds the storage and handling procedures utilized by S&S.</p>
          <p className='content'>You must provide S&S with written notice of any warranty claims no later than thirty (30) days after receipt of the applicable order of Goods. Failure to provide written notice within such 30-day period shall void S&S's warranties in their entirety. As S&S's sole responsibility and liability, and YOUR ONLY AND EXCLUSIVE REMEDY for any breach or breaches of such warranties, S&S shall, upon written notice from you, either (at S&S's option) replace the defective portion of the Goods, or accept return thereof and refund the price paid by you for the defective portion. Any misuse, improper handling, storage, use, modification or alteration of the Goods by any third party shall void the forgoing warranty. You shall remain entirely responsible for any shipments by you or your company for purposes of returning defective products or packages hereunder to S&S, and all risk of loss or damage during shipment shall be borne by you. S&S will pay (or reimburse you) for reasonable shipping and handling charges limited to valid warranty claims.</p>
          <p className='content'>You agree and acknowledge that any benefits derived from use or resale of the Goods will depend on factors which vary from business to business and which are not within S&S's control. YOU ARE RESPONSIBLE FOR THE SELECTION OF THE GOODS TO MEET YOUR, YOUR COMPANY'S OR ITS CUSTOMERS' NEEDS, AND S&S MAKES NO WARRANTY AS TO THE RESULTS TO BE OBTAINED FROM THE USE OR SALE OF THE GOODS IN YOUR BUSINESS. S&S EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND WITH RESPECT TO THE GOODS WHETHER EXPRESS OR IMPLIED INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. THE WARRANTIES SET FORTH IN THIS SECTION ARE THE ONLY WARRANTIES MADE BY S&S.</p>
          <h6 className='title'>LIMITATION OF LIABILITY AND EXCLUSIONS</h6>
          <p className='content'>IN NO EVENT WILL S&S BE LIABLE FOR ANY INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR RESULTING FROM THE PERFORMANCE OR BREACH OF THIS AGREEMENT, OR THE USE OR SALE OF GOODS BY YOU, OR ANY OTHER PARTY, OR FROM THE MANUFACTURE, SALE OR USE OF ANYTHING MADE BASED ON THE GOODS, EVEN IF S&S IS AWARE OF THE POSSIBILITY OF SUCH DAMAGES, EXCEPT FOR INJURIES TO CONSUMERS DUE TO THE GROSS NEGLIGENCE OR WILLFUL MISCONDUCT OF S&S. You agree that, regardless of the form of action, whether in contract or tort, including negligence, S&S's liability for damages claimed by you with respect to the Goods shall not exceed fees received by S&S from you for the applicable Goods hereunder. Regardless of the form of action, whether in contract or tort, including negligence, S&S's liability for damages claimed by third parties with respect to the Goods, as between S&S and you shall not exceed fees paid to S&S hereunder. No action, regardless of form, arising under this Sale Agreement (other than an action for non-payment of any purchase prices or other amounts owed by you to S&S), may be brought by either party more than one (1) year after the date of the alleged breach. S&S shall not be liable for any failure to perform under this Sale Agreement where such failure is due to any cause beyond S&S's control. You hereby release and shall defend and hold S&S and its owners and agents harmless from and against any actual or threatened claims, losses, liabilities (including without limitation any punitive damages and fines), costs and expenses (including without limitation reasonable costs of litigation and attorneys' fees) related to third party actions (a) in which it is determined that S&S is not at fault; and/or (b) arising from or relating to any acts or omissions by you, your company or your customers.</p>
          <p className='content'>You hereby release and shall defend and hold S&S and its owners and agents harmless from and against any actual or threatened claims, losses, liabilities (including without limitation any punitive damages and fines), costs and expenses (including without limitation reasonable costs of litigation and attorneys' fees) related to third party actions (a) in which it is determined that S&S is not at fault; and/or (b) arising from or relating to any acts or omissions by you, your company or your customers.</p>
          <h6 className='title'>GENERAL</h6>
          <p className='content'>Our relationship is one of independent contractors. No agency, employment, partnership or joint venture shall be created by or founded upon this Sale Agreement. You shall not make or assign, or represent to any party, that it may make or assign, any warranty or representation by or for S&S, nor shall you attempt, or represent that it is entitled, to make any commitment, waiver or settlement on behalf of S&S or to pledge the credit of S&S. This Sale Agreement shall be governed by and construed under the laws of New York, without regard to any conflicts of law principles to the contrary. The parties consent to exclusive jurisdiction and venue in the federal courts sitting in Cook County, New York, unless no federal subject matter jurisdiction exists, in which case the parties consent to the exclusive jurisdiction and venue in the Circuit Courts of Will County, New York. You agree not to object to this jurisdiction and venue, and hereby waive all defenses of lack of personal jurisdiction and forum non-conveniens. Any notice or request hereunder shall be made in writing delivered in person to an authorized officer of the respective party or mailed or transmitted by cable or telecopier, for S&S, to the current contact information on our website at www.ssactivewear.com, and, for you, to the address S&S has on file for you in your most recent approved credit application (unless changed by written notice of a different address).</p>
          <p className='content'>Your rights hereunder are personal to you and the company you represent, and may not be assigned or transferred in whole or in part by you, nor may any benefit hereunder inure to any trustee in bankruptcy, receiver, or successor, whether by operation of law or otherwise, without the prior written consent of S&S, and any attempted assignment or transfer without such consent shall constitute a breach hereunder and shall be void. No omission or delay on the part of either party hereto in requiring due and punctual fulfillment of the obligations of the other party shall be deemed to constitute a waiver of any of the rights of the omitting or delaying party unless such rights are waived in the particular instance in a writing delivered to the other party, and no such waiver shall apply to any other instance or obligation. If any provision of this Sale Agreement is held to be invalid or unenforceable to any extent in any context, it shall nevertheless be enforced to the maximum extent allowed by law and the parties' fundamental intentions in that and other contexts, and the remainder of this Sale Agreement shall not be affected thereby.</p>
        </div>
        {/* footer  */}
        <Footer/>
    </>
  )
};
export default TermsConditions;