import React, {useEffect, useState} from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Sidebar from './shared/Sidebar';
import { Link } from 'react-router-dom';
import axios from 'axios';
import appConstant from './shared/constant/constant.json';
import LoadingSpinner from './shared/Loader';
function ManageCards() {
  return (
    <>
        {/* header  */}
        <Header/>
        <div className='cart-section page-spacing'>
          <div className='breadcrumbs'>
              <Link to="/">Home / </Link> <strong>Manage Saved Cards</strong>
          </div>
          <div className='row'>
            <div className='col-md-3'>
                <div className='card'>
                  <div className="card-body">
                      <h6 className='title'>MY ACCOUNT</h6>
                      <Sidebar id='4'/>
                  </div>
                </div>
            </div>
            <div className='col-md-9'>
                <div className='d-flex justify-content-between mb-3'>
                    <h6 className='title mb-4'>Manage Saved Cards</h6> <a href='/add-new-card' className='action-btn'><img alt='edit' src='/images/plus.png'/> Add New Card</a>
                </div>
              <table className='table table-bordered text-start table-striped'>
                <thead>
                    <tr>
                        <th>S.No.</th>
                        <th>Card Number</th>
                        <th>Card Holder Name</th>
                        <th>Card Type</th>
                        <th>Expiry Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td><h6 className='mb-0'>xxxxxxxxxxxx9876</h6></td>
                        <td><p className='mb-0'>Manish Goyal</p></td>
                        <td><p className='mb-0'>Credit Card</p></td>
                        <td><p className='mb-0'>03/24</p></td>
                        <td>
                            <button type='button' className='action-btn'><img alt='edit' src='/images/edit.png'/></button> &nbsp;&nbsp;
                            <button type='button' className='action-btn'><img alt='trash' src='/images/trash.png' /></button>
                        </td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td><h6 className='mb-0'>xxxxxxxxxxxx9876</h6></td>
                        <td><p className='mb-0'>Manish Goyal</p></td>
                        <td><p className='mb-0'>Debit Card</p></td>
                        <td><p className='mb-0'>03/24</p></td>
                        <td>
                            <button type='button' className='action-btn'><img alt='edit' src='/images/edit.png'/></button> &nbsp;&nbsp;
                            <button type='button' className='action-btn'><img alt='trash' src='/images/trash.png' /></button>
                        </td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td><h6 className='mb-0'>xxxxxxxxxxxx9876</h6></td>
                        <td><p className='mb-0'>Manish Goyal</p></td>
                        <td><p className='mb-0'>Credit Card</p></td>
                        <td><p className='mb-0'>03/24</p></td>
                        <td>
                            <button type='button' className='action-btn'><img alt='edit' src='/images/edit.png'/></button> &nbsp;&nbsp;
                            <button type='button' className='action-btn'><img alt='trash' src='/images/trash.png' /></button>
                        </td>
                    </tr>
                </tbody>
              </table>
              
            </div>
          </div>
        </div>
        {/* footer  */}
        <Footer/>
    </>
  )
};
export default ManageCards;