import React from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { Link } from 'react-router-dom';

function Returns() {
  return (
    <>
        {/* header  */}
        <Header/>
        <div className='static-page-banner'></div>
        <div className='static-page-content page-spacing mb-4'>          
          <div className='breadcrumbs'>
            <Link to="/">Home / </Link> <strong>Returns</strong>
          </div>
          <h6 className='heading'>Returns</h6>          
          <p className='content'>If you have a problem or discrepancy with an order, contact our Help Desk at 718-328 0001. Please have your invoice ready along with the style number, color and quantity you are inquiring about.</p>
          <ul className='static-page-list'>
            <li>Absolutely no returns on printed, washed or decorated merchandise. Garments must be inspected before printing. Labels should not be removed or altered.</li>
            <li>All claims for shortages, damages, etc. must be made within 72 hours of receipt of merchandise.</li>
            <li>No cash refunds.</li>
            <li>All returned or refused shipments are subject to a 20% or $25 (whichever is greater) restocking charge in addition to both outbound and return freight costs and COD charges.</li>
            <li>Without a valid return authorization number, our warehouse will not accept returns.</li>
            <li>Defective merchandise must be returned to us with a piece of tape indicating the location of the flaw.</li>
            <li>Discontinued styles or merchandise over 30 days old cannot be returned.</li>
            <li>Please include a copy of the invoice to ensure proper credit in a timely manner. (Approximately two weeks)</li>
            <li>If your order is not processed accurately, we will cover the original freight and send a call tag for the incorrect merchandise.</li>
          </ul>
        </div>
        {/* footer  */}
        <Footer/>
    </>
  )
};
export default Returns;