import React, { useEffect, useState } from "react";
import Header from "./shared/Header";
import Footer from "./shared/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import appConstant from "./shared/constant/constant.json";
import LoadingSpinner from "./shared/Loader";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function Home() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1921 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 1920, min: 1380 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1380, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 2,
    },
  };
  const responsive1 = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1921 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1920, min: 1380 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1380, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 767, min: 576 },
      items: 2,
    },
    small_mobile: {
      breakpoint: { max: 575, min: 0 },
      items: 1,
    },
  };
  const [category, setCategory] = useState([]);
  const [product1, setProduct1] = useState([]);
  const [product2, setProduct2] = useState([]);
  const [product3, setProduct3] = useState([]);
  const [product4, setProduct4] = useState([]);
  const [product5, setProduct5] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    categoryData();
    products('tshirts');
    products('wovens');
    products('bags');
    products('outerwear');
    products('fleece');
  }, []);

  const categoryData = () => {
    const params = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
    axios.get(`${appConstant.API_URL}/brands/category`, params)
        .then(res => {
            if(res?.data?.status === 200){
                setCategory(res?.data?.data);
            }
        })
        .catch(error => {
        });
}
const categoryCTA = (slug) => {
  window.location.href = '/category/' + slug;
}
  const products = (slug) => {
    setIsLoading(true);
    const params = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    axios
      .get(
        `${appConstant.API_URL}/brands/brand_details/category/${slug}/1`,
        params
      )
      .then((res) => {
        setIsLoading(false);
        switch(slug){
            case 'tshirts':
            setProduct1(res?.data?.data);
            break;
            case 'wovens':
            setProduct2(res?.data?.data);
            break;
            case 'bags':
            setProduct3(res?.data?.data);
            break;
            case 'outerwear':
            setProduct4(res?.data?.data);
            break;
            case 'fleece':
            setProduct5(res?.data?.data);
            break;
        }
        
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  return (
    <>
      {/* header  */}
      <Header id="1" />

      <div className="container mt-4">
        <section className="promo-banner d-none d-md-flex">
          <h1 className="promo-banner-label">
            Low <br />
            Total Price
          </h1>

          <div className="promo-banner-arrow">
            <div className="promo-banner-arrow-top"></div>
            <div className="promo-banner-arrow-bottom"></div>
          </div>

          <div className="promo-banner_list">
            <ul className="promo-banner-list">
              <li className="promo-banner-list-item">
                <a className="promo-banner-list-link" href="#">
                  {" "}
                  Bulk Discount <br />
                  <span className="promo-banner-list-link-second-line">
                    {" "}
                    at $99
                  </span>
                </a>
              </li>
              <li className="promo-banner-list-item">
                <a className="promo-banner-list-link" href="#">
                  Free Shipping <br />
                  <span className="promo-banner-list-link-second-line">
                    at $59
                    <sup className="promo-banner-list-link-second-line-info">
                      *
                    </sup>
                  </span>
                </a>
              </li>
              <li className="promo-banner-list-item">
                <a className="promo-banner-list-link" href="#">
                  {" "}
                  Free Return <br />
                  <span className="promo-banner-list-link-second-line">
                    Within 100 Days{" "}
                  </span>
                </a>
              </li>
              <li className="promo-banner-list-item">
                <a className="promo-banner-list-link" href="#">
                  Delivery <br />
                  <span className="promo-banner-list-link-second-line">
                    {" "}
                    Date In Cart{" "}
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </section>
        {/* hero  */}
        <section className="hero-section mb-4">
          <div id="hero" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <Link to="/brands/fruit-of-the-loom">
                  <img
                    src="/images/1.avif"
                    alt="Los Angeles"
                    className="d-inline-block"
                  />
                </Link>
              </div>
              <div className="carousel-item">
                <Link to="/brands/gildan">
                  <img
                    src="/images/2.avif"
                    alt="Los Angeles"
                    className="d-inline-block"
                  />
                </Link>
              </div>
              <div className="carousel-item">
                <Link to="/brands/jerzees">
                  <img
                    src="/images/3.avif"
                    alt="Los Angeles"
                    className="d-inline-block"
                  />
                </Link>
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#hero"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon"></span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#hero"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon"></span>
            </button>
          </div>
        </section>
        <div className="row">
          <div className="col-md-3 d-none d-md-block">
            <div className="left-filters">
              <h5 className="filter-heading">Category</h5>
              
              <ul className="filter-list">
                {category?.length > 0 && category?.map((result, i) =>
                    <>
                        <li className="filter-item" key={'category' + i}>
                          <div onClick={() => categoryCTA(result?.slug)}>{result?.category_actual_name}</div>
                        </li>
                    </>
                )}
              </ul>
              {/* <hr /> */}
              {/* <h5 className="filter-heading">Cut</h5>
              <ul className="filter-list">
                <li className="filter-item">
                  <Link to="/">
                    {" "}
                    Adult <span>(123)</span>
                  </Link>
                </li>
                <li className="filter-item">
                  <Link to="/">
                    {" "}
                    Women <span>(123)</span>
                  </Link>
                </li>
                <li className="filter-item">
                  <Link to="/">
                    {" "}
                    Man <span>(123)</span>
                  </Link>
                </li>
                <li className="filter-item show">
                  <Link to="/"> Show all</Link>
                </li>
              </ul> */}
              <hr />

              <p className="note">*Price varies with size and color.</p>
            </div>
          </div>
          <div className="col-md-9">
            {/* <div className='right-filters'>
                <h4 className="filter-heading">Quick Style Finder</h4>
                <div className='row'>
                  <div className='col-md-4'>
                    <select className='form-control' name="category">
                      <option>Category</option>
                    </select>
                  </div>
                  <div className='col-md-4'>
                    <select className='form-control' name="color">
                      <option>Color</option>
                    </select>
                  </div>
                  <div className='col-md-4'>
                    <select className='form-control' name="decoration">
                      <option>Decoration Method</option>
                    </select>
                  </div>
                  <div className='col-md-5'>
                    <select className='form-control' name="cut">
                      <option>Cut</option>
                    </select>
                  </div>
                  <div className='col-md-7'>
                      <div className='size-box'>
                          <input className='size-input' type="radio" name="sizeName" value="ash"/>
                          <label className='size-label'>
                              XL
                          </label>
                      </div>
                      <div className='size-box'>
                          <input className='size-input' type="radio" name="sizeName" value="ash"/>
                          <label className='size-label'>
                              L
                          </label>
                      </div>
                      <div className='size-box'>
                          <input className='size-input' type="radio" name="sizeName" value="ash"/>
                          <label className='size-label'>
                              M
                          </label>
                      </div>
                      <div className='size-box'>
                          <input className='size-input' type="radio" name="sizeName" value="ash"/>
                          <label className='size-label'>
                              S
                          </label>
                      </div>
                  </div>
                  <button className='filter-buttons'>Apply</button>
                </div>
              </div> */}

            <section className="mb-4">
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <div className="flash-sale shop-section p-0">
                  <h5 className="heading background">
                    Youth T-Shirts
                    <Link className="float-end links" to="/category/tshirts">
                      View All
                    </Link>
                  </h5>
                  <div className="d-flex flex-wrap justify-content-between">
                    {product1?.data?.length > 0 &&
                      product1?.data?.map((result, i) => (
                        <div
                          key={"flash-sale" + i}
                          className="flex-columns mb-3"
                        >
                          <h6 className="card-title">{result?.styleName}</h6>
                          <div className="card">
                            <Link to={"/product/" + result?.product_slug}>
                              <div className="item-zoom">
                                <img
                                  className="card-img-top zoom-img"
                                  src={appConstant.CDN_URL + result?.styleImage}
                                  alt="Card"
                                />
                                <div className="badge"> On Sale</div>
                              </div>
                            </Link>
                            <div className="card-body p-2">
                              {/* when not login */}
                              {/* {!localStorage.getItem("token") && (
                                <p className="card-text">Call for Pricing</p>
                              )} */}
                              {/* when not login end*/}
                              {/* when login */}
                              {/* {localStorage.getItem("token") && ( */}
                                <p className="card-price sale-price">
                                  Starting at ${result?.meta[0]?.user_price}
                                </p>
                              {/* )} */}
                              {/* when login end */}
                              <h4 className="card-title">
                                {result?.brandName}
                              </h4>
                              <p className="card-price product-name">
                                {result?.products_name}
                              </p>

                              <div className="color-box-height">
                                {result?.meta?.length > 0 &&
                                  result?.meta?.map((result1, i) => (
                                    <div className="color-box">
                                      <input
                                        className="color-input"
                                        type="radio"
                                        name="colorName"
                                        value="ash"
                                      />
                                      <label className="color-label">
                                        <img
                                          className="color-image"
                                          src={
                                            appConstant.CDN_URL +
                                            result1?.colorSwatchImage
                                          }
                                          alt="color"
                                          title={result1?.colorName}
                                        />
                                      </label>
                                    </div>
                                  ))}
                                {result?.meta_total > 10 && (
                                  <span className="card-text d-inline-block ms-1">
                                    {" "}
                                    +{result?.meta_total - 9}
                                  </span>
                                )}
                                {/*<p className='card-price'>S - XXL</p>*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </section>
            <section className="mb-4">
              <div className="flash-sale shop-section p-0">
                <h5 className="heading">Top Selling Brands </h5>
                <Carousel
                  responsive={responsive}
                  autoPlay={true}
                  autoPlaySpeed={3000}
                  infinite
                  arrows={true}
                >
                  <div className="client-box">
                    <img
                      className="clients-images"
                      src="/images/logo-8.png"
                      alt="logo-6"
                    />
                  </div>
                  <div className="client-box">
                    <img
                      className="clients-images"
                      src="/images/nextlevel.png"
                      alt="logo-2"
                    />
                  </div>
                  <div className="client-box">
                    <img
                      className="clients-images"
                      src="/images/logo-5.png"
                      alt="logo-4"
                    />
                  </div>
                  <div className="client-box">
                    <img
                      className="clients-images"
                      src="/images/logo-2.png"
                      alt="logo-3"
                    />
                  </div>
                  <div className="client-box">
                    <img
                      className="clients-images"
                      src="/images/independent.png"
                      alt="logo-3"
                    />
                  </div>
                  <div className="client-box">
                    <img
                      className="clients-images"
                      src="/images/logo-6.png"
                      alt="logo-5"
                    />
                  </div>
                  <div className="client-box">
                    <img
                      className="clients-images"
                      src="/images/activa.png"
                      alt="logo-6"
                    />
                  </div>
                </Carousel>
              </div>
            </section>
            {isLoading ? (
                <LoadingSpinner />
              ) : (
                <div className="flash-sale shop-section p-0">
                  <h5 className="heading background">
                    Wovens
                    <Link className="float-end links" to="/category/wovens">
                      View All
                    </Link>
                  </h5>
                  <div className="d-flex flex-wrap justify-content-between">
                    {product2?.data?.length > 0 &&
                      product2?.data?.map((result, i) => (
                        <div
                          key={"flash-sale" + i}
                          className="flex-columns mb-3"
                        >
                          <h6 className="card-title">{result?.styleName}</h6>
                          <div className="card">
                            <Link to={"/product/" + result?.product_slug}>
                              <div className="item-zoom">
                                <img
                                  className="card-img-top zoom-img"
                                  src={appConstant.CDN_URL + result?.styleImage}
                                  alt="Card"
                                />
                                <div className="badge"> On Sale</div>
                              </div>
                            </Link>
                            <div className="card-body p-2">
                              {/* when not login */}
                              {/* {!localStorage.getItem("token") && (
                                <p className="card-text">Call for Pricing</p>
                              )} */}
                              {/* when not login end*/}
                              {/* when login */}
                              {/* {localStorage.getItem("token") && ( */}
                                <p className="card-price sale-price">
                                  Starting at ${result?.meta[0]?.user_price}
                                </p>
                              {/* )} */}
                              {/* when login end */}
                              <h4 className="card-title">
                                {result?.brandName}
                              </h4>
                              <p className="card-price product-name">
                                {result?.products_name}
                              </p>

                              <div className="color-box-height">
                                {result?.meta?.length > 0 &&
                                  result?.meta?.map((result1, i) => (
                                    <div className="color-box">
                                      <input
                                        className="color-input"
                                        type="radio"
                                        name="colorName"
                                        value="ash"
                                      />
                                      <label className="color-label">
                                        <img
                                          className="color-image"
                                          src={
                                            appConstant.CDN_URL +
                                            result1?.colorSwatchImage
                                          }
                                          alt="color"
                                          title={result1?.colorName}
                                        />
                                      </label>
                                    </div>
                                  ))}
                                {result?.meta_total > 10 && (
                                  <span className="card-text d-inline-block ms-1">
                                    {" "}
                                    +{result?.meta_total - 9}
                                  </span>
                                )}
                                {/*<p className='card-price'>S - XXL</p>*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            <section className="mb-4">
              <div className="payment-logos text-center">
                <h5 className="payment-logos-title ">Easy Shopping</h5>
                <img
                  className="w-75"
                  src="/images/payment-method_pre.png"
                  alt="payment"
                />
              </div>
            </section>
            {isLoading ? (
                <LoadingSpinner />
              ) : (
                <div className="flash-sale shop-section p-0">
                  <h5 className="heading background">
                  Bags
                    <Link className="float-end links" to="/category/bags">
                      View All
                    </Link>
                  </h5>
                  <div className="d-flex flex-wrap justify-content-between">
                    {product3?.data?.length > 0 &&
                      product3?.data?.map((result, i) => (
                        <div
                          key={"flash-sale" + i}
                          className="flex-columns mb-3"
                        >
                          <h6 className="card-title">{result?.styleName}</h6>
                          <div className="card">
                            <Link to={"/product/" + result?.product_slug}>
                              <div className="item-zoom">
                                <img
                                  className="card-img-top zoom-img"
                                  src={appConstant.CDN_URL + result?.styleImage}
                                  alt="Card"
                                />
                                <div className="badge"> On Sale</div>
                              </div>
                            </Link>
                            <div className="card-body p-2">
                              {/* when not login */}
                              {/* {!localStorage.getItem("token") && (
                                <p className="card-text">Call for Pricing</p>
                              )} */}
                              {/* when not login end*/}
                              {/* when login */}
                              {/* {localStorage.getItem("token") && ( */}
                                <p className="card-price sale-price">
                                  Starting at ${result?.meta[0]?.user_price}
                                </p>
                              {/* )} */}
                              {/* when login end */}
                              <h4 className="card-title">
                                {result?.brandName}
                              </h4>
                              <p className="card-price product-name">
                                {result?.products_name}
                              </p>

                              <div className="color-box-height">
                                {result?.meta?.length > 0 &&
                                  result?.meta?.map((result1, i) => (
                                    <div className="color-box">
                                      <input
                                        className="color-input"
                                        type="radio"
                                        name="colorName"
                                        value="ash"
                                      />
                                      <label className="color-label">
                                        <img
                                          className="color-image"
                                          src={
                                            appConstant.CDN_URL +
                                            result1?.colorSwatchImage
                                          }
                                          alt="color"
                                          title={result1?.colorName}
                                        />
                                      </label>
                                    </div>
                                  ))}
                                {result?.meta_total > 10 && (
                                  <span className="card-text d-inline-block ms-1">
                                    {" "}
                                    +{result?.meta_total - 9}
                                  </span>
                                )}
                                {/*<p className='card-price'>S - XXL</p>*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
            )}

            {isLoading ? (
                <LoadingSpinner />
              ) : (
                <div className="flash-sale shop-section p-0 mt-3">
                  <h5 className="heading background">
                  Outerwear
                    <Link className="float-end links" to="/category/outerwear">
                      View All
                    </Link>
                  </h5>
                  <div className="d-flex flex-wrap justify-content-between">
                    {product4?.data?.length > 0 &&
                      product4?.data?.map((result, i) => (
                        <div
                          key={"flash-sale" + i}
                          className="flex-columns mb-3"
                        >
                          <h6 className="card-title">{result?.styleName}</h6>
                          <div className="card">
                            <Link to={"/product/" + result?.product_slug}>
                              <div className="item-zoom">
                                <img
                                  className="card-img-top zoom-img"
                                  src={appConstant.CDN_URL + result?.styleImage}
                                  alt="Card"
                                />
                                <div className="badge"> On Sale</div>
                              </div>
                            </Link>
                            <div className="card-body p-2">
                              {/* when not login */}
                              {/* {!localStorage.getItem("token") && (
                                <p className="card-text">Call for Pricing</p>
                              )} */}
                              {/* when not login end*/}
                              {/* when login */}
                              {/* {localStorage.getItem("token") && ( */}
                                <p className="card-price sale-price">
                                  Starting at ${result?.meta[0]?.user_price}
                                </p>
                              {/* )} */}
                              {/* when login end */}
                              <h4 className="card-title">
                                {result?.brandName}
                              </h4>
                              <p className="card-price product-name">
                                {result?.products_name}
                              </p>

                              <div className="color-box-height">
                                {result?.meta?.length > 0 &&
                                  result?.meta?.map((result1, i) => (
                                    <div className="color-box">
                                      <input
                                        className="color-input"
                                        type="radio"
                                        name="colorName"
                                        value="ash"
                                      />
                                      <label className="color-label">
                                        <img
                                          className="color-image"
                                          src={
                                            appConstant.CDN_URL +
                                            result1?.colorSwatchImage
                                          }
                                          alt="color"
                                          title={result1?.colorName}
                                        />
                                      </label>
                                    </div>
                                  ))}
                                {result?.meta_total > 10 && (
                                  <span className="card-text d-inline-block ms-1">
                                    {" "}
                                    +{result?.meta_total - 9}
                                  </span>
                                )}
                                {/*<p className='card-price'>S - XXL</p>*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
            )}

            {isLoading ? (
                <LoadingSpinner />
              ) : (
                <div className="flash-sale shop-section p-0 mt-3">
                  <h5 className="heading background">
                    Fleece
                    <Link className="float-end links" to="/category/fleece">
                      View All
                    </Link>
                  </h5>
                  <div className="d-flex flex-wrap justify-content-between">
                    {product5?.data?.length > 0 &&
                      product5?.data?.map((result, i) => (
                        <div
                          key={"flash-sale" + i}
                          className="flex-columns mb-3"
                        >
                          <h6 className="card-title">{result?.styleName}</h6>
                          <div className="card">
                            <Link to={"/product/" + result?.product_slug}>
                              <div className="item-zoom">
                                <img
                                  className="card-img-top zoom-img"
                                  src={appConstant.CDN_URL + result?.styleImage}
                                  alt="Card"
                                />
                                <div className="badge"> On Sale</div>
                              </div>
                            </Link>
                            <div className="card-body p-2">
                              {/* when not login */}
                              {/* {!localStorage.getItem("token") && (
                                <p className="card-text">Call for Pricing</p>
                              )} */}
                              {/* when not login end*/}
                              {/* when login */}
                              {/* {localStorage.getItem("token") && ( */}
                                <p className="card-price sale-price">
                                  Starting at ${result?.meta[0]?.user_price}
                                </p>
                              {/* )} */}
                              {/* when login end */}
                              <h4 className="card-title">
                                {result?.brandName}
                              </h4>
                              <p className="card-price product-name">
                                {result?.products_name}
                              </p>

                              <div className="color-box-height">
                                {result?.meta?.length > 0 &&
                                  result?.meta?.map((result1, i) => (
                                    <div className="color-box">
                                      <input
                                        className="color-input"
                                        type="radio"
                                        name="colorName"
                                        value="ash"
                                      />
                                      <label className="color-label">
                                        <img
                                          className="color-image"
                                          src={
                                            appConstant.CDN_URL +
                                            result1?.colorSwatchImage
                                          }
                                          alt="color"
                                          title={result1?.colorName}
                                        />
                                      </label>
                                    </div>
                                  ))}
                                {result?.meta_total > 10 && (
                                  <span className="card-text d-inline-block ms-1">
                                    {" "}
                                    +{result?.meta_total - 9}
                                  </span>
                                )}
                                {/*<p className='card-price'>S - XXL</p>*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
            )}
            
          </div>
        </div>
        <hr />
        <h4 className="heading text-center mb-2 mt-4">Reviews</h4>
        <p className="text-center mb-0">
          <strong>4.9</strong> rating | <strong>72,709</strong> reviews
        </p>
        <div className="text-center">
          <img src="/images/star.png" alt="star" />
          <img src="/images/star.png" alt="star" />
          <img src="/images/star.png" alt="star" />
          <img src="/images/star.png" alt="star" />
          <img src="/images/star.png" alt="star" />
        </div>
        <div className="mt-3 mb-5">
          <Carousel
            responsive={responsive1}
            autoPlay={true}
            autoPlaySpeed={3000}
            infinite
            arrows={true}
          >
            <div className="review">
              <p className="review-date">10 oct 2023</p>
              <p className="review-name">Ashlynn F.</p>
              <div className="mb-2">
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
              </div>
              <p className="review-content">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
            <div className="review">
              <p className="review-date">10 oct 2023</p>
              <p className="review-name">Ashlynn F.</p>
              <div className="mb-2">
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
              </div>
              <p className="review-content">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
            <div className="review">
              <p className="review-date">10 oct 2023</p>
              <p className="review-name">Ashlynn F.</p>
              <div className="mb-2">
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
              </div>
              <p className="review-content">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
            <div className="review">
              <p className="review-date">10 oct 2023</p>
              <p className="review-name">Ashlynn F.</p>
              <div className="mb-2">
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
              </div>
              <p className="review-content">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
            <div className="review">
              <p className="review-date">10 oct 2023</p>
              <p className="review-name">Ashlynn F.</p>
              <div className="mb-2">
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
              </div>
              <p className="review-content">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
            <div className="review">
              <p className="review-date">10 oct 2023</p>
              <p className="review-name">Ashlynn F.</p>
              <div className="mb-2">
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
              </div>
              <p className="review-content">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
            <div className="review">
              <p className="review-date">10 oct 2023</p>
              <p className="review-name">Ashlynn F.</p>
              <div className="mb-2">
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
                <img src="/images/star.png" alt="star" />
              </div>
              <p className="review-content">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
          </Carousel>
        </div>
      </div>
      {/* footer  */}
      <Footer />
    </>
  );
}

export default Home;
