import React from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { Link } from 'react-router-dom';

function KeyBrands() {
  return (
    <>
        {/* header  */}
        <Header/>
        <div className='static-page-banner'></div>
        <div className='static-page-content page-spacing mb-4'>          
          <div className='breadcrumbs'>
            <Link to="/">Home / </Link> <strong>Key Brands</strong>
          </div>
          <h6 className='heading'>Key Brands</h6>
          <h6 className='title'>Basics</h6>
          <ul className='static-page-list'>
            <li><strong>Fruit of the Loom - </strong>Colorful T-shirts providing quality, value and style</li>
            <li><strong>Gildan - </strong>Contemporary or classically crafted apparel, with an ever-expanding color palette covering a spectrum of bold brights to subtle hues</li>
            <li><strong>Hanes - </strong>Iconic everyday basic apparel known for its quality and comfort</li>
            <li><strong>Jerzees - </strong>T-shirts, Fleece and Athletics delivering quality and everyday value</li>
          </ul>
          <hr/>
          <h6 className='title'>Wovens / Dress Shirts</h6>
          <ul className='static-page-list'>
            <li><strong>Burnside  - </strong>Unique brand that delivers quality lifestyle apparel to the trend setter</li>
            <li><strong>Calvin Klein  - </strong>Attention to detail that gives corporate groups and edge</li>
            <li><strong>FeatherLite - </strong>Professional performance uniforms that resist stains and spills</li>
            <li><strong>Sierra Pacif - </strong>Sustainable wovens sized to fit the entire staff</li>
            <li><strong>Van Heusen - </strong>Wrinkle-free expert tailoring, classic corporate fashion</li>
            <li><strong>Weatherproof - </strong>The latest fashion trends to reflect the easy living lifestyle</li>                
          </ul>
          <hr/>
          <h6 className='title'>Polos / Sport Shirts</h6>
          <ul className='static-page-list'>
            <li><strong>adidas  - </strong>The iconic athletic brand: performance, style and comfort</li>
            <li><strong>IZOD  - </strong>Retail staple with classic and performance styles</li>
            <li><strong>Oakley  - </strong>Superior design with technical details</li>
          </ul>
          <hr/>
          <h6 className='title'>Fashion T-Shirts</h6>
          <ul className='static-page-list'>
            <li><strong>Alternative  - </strong>Edgy basics with signature vintage softness</li>
            <li><strong>Anvil  - </strong>Ring spun softness that is both super-comfortable and effortlessly cool</li>
            <li><strong>Bella+Canvas  - </strong>Tees & fleece for men and women - from basics to what's new and on-trend</li>
            <li><strong>LAT - </strong>Outfit any shape and size from youth to adult</li>
            <li><strong>Next Level   - </strong>Fashion basics with superior printability and soft, comfortable styling</li>
          </ul>
          <hr/> 
          <h6 className='title'>Fashion Fleece</h6>
          <ul className='static-page-list'>
            <li><strong>Alternative - </strong>Edgy basics with signature vintage softness</li>
            <li><strong>Comfort Colors - </strong>Styles with a world of worn-in natural shades and an ever-growing spectrum of colors</li>
            <li><strong>Independent Trading Co. - </strong>Surf-inspired soft casual styles</li>
            <li><strong>J. America - </strong>Collegiate-inspired fleece with unique fabrications</li>
            <li><strong>MV Sport - </strong>Cross-weave hoodies, varsity jackets and pullovers</li>
          </ul>
          <hr/>
          <h6 className='title'>Outerwear</h6>
          <ul className='static-page-list'>
            <li><strong>Colorado Clothing - </strong>Outerwear inspired by active lifestyles and the beauty of the outdoors</li>
            <li><strong>Dri Duck - </strong>Work wear and performance outdoor apparel</li>
            <li><strong>Stormtech - </strong>Highly technical outerwear</li>
            <li><strong>Weatherproof - </strong>Top retail outerwear brand - classic corporate jackets and outerwear</li>
          </ul>
          <hr/> 
          <h6 className='title'>Athletics</h6>
          <ul className='static-page-list'>
            <li><strong>All Sport - </strong>High performance activewear for women and men</li>
            <li><strong>adidas - </strong>The iconic athletic brand: performance, style and comfort</li>
            <li><strong>Augusta - </strong>Variety of wind wear and running wear styles for men, women, and youth</li>
            <li><strong>Badger - </strong>Team, high school, collegiate, spirit, cheer, and performance gear</li>
            <li><strong>Boxercraft - </strong>Signature flannel, cozy fleece and jersey styles for all spiritwear needs</li>
            <li><strong>Champion - </strong>Authentic sports apparel</li>
            <li><strong>Oakley - </strong>Superior styling with technical details - polos, bags and hats</li>
            <li><strong>Rawlings - </strong>Technologically-enhanced products for both the coach and players of a baseball team</li>
          </ul>
          <hr/> 
          <h6 className='title'>Workwear</h6>
          <ul className='static-page-list'>
            <li><strong>Bayside - </strong>USA MADE and Union Made goods</li>
            <li><strong>Bulwark - </strong>Arm yourself with the best flame resistant brand</li>
            <li><strong>Dri Duck - </strong>Power Fleece, heavy canvas, and extra durable work shirts</li>
            <li><strong>ML Kishigo - </strong>Ideal safety gear for ANSI/ISEA compliance</li>
            <li><strong>Red Kap - </strong>Real workwear done right</li>
            <li><strong>Wrangler - </strong>Wrangler workwear is worthy of work</li>
          </ul>
          <hr/> 
          <h6 className='title'>Youth/Infant/Toddler</h6>
          <ul className='static-page-list'>
            <li><strong>French Toast -</strong>School uniforms for both boys and girls with comfort that lasts</li>
            <li><strong>Rabbit Skins - </strong>Part of the LAT family, bringing you toddler styles and infant creepers</li>
          </ul>
          <hr/>  
          <h6 className='title'>Accessories</h6>
          <ul className='static-page-list'>
            <li><strong>adidas - </strong>The iconic athletic brand: performance, style and comfort</li>
            <li><strong>Flexfit - </strong>Don't pass up the perfect fit for headwear</li>
            <li><strong>Oakley - </strong>Superior design with technical details</li>
            <li><strong>Ame & Lulu - </strong>Accessories for an active lifestyle</li>
            <li><strong>Towels Plus - </strong>The same style, quality and softness you can expect from Anvil</li>
            <li><strong>Activa </strong></li>
            <li><strong>Cute </strong></li>
            <li><strong>ICE </strong></li>
            <li><strong>S & S Fashions label.</strong></li>
            <li><strong>I Love NY products</strong></li>
          </ul>                 
        </div>
        {/* footer  */}
        <Footer/>
    </>
  )
};
export default KeyBrands;