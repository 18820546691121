import React from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { Link } from 'react-router-dom';
import MetaTags from "react-meta-tags";

function AboutUs() {
  return (
    <>
        <MetaTags>
            <title>About Us - S & S Fashions Inc.</title>
            <meta name="description" content={"S & S Fashions Inc. About Us"} />
            <meta property="og:title" content={'About Us - S & S Fashions Inc.'} />
            <meta property="og:image" content={'/images/time-bg.jpg'} />
        </MetaTags>
        {/* header  */}
        <Header id="6"/>
        <div className='static-page-banner'></div>
        <div className='static-page-content page-spacing mb-4'>          
          <div className='breadcrumbs'>
              <Link to="/">Home / </Link> <strong>About Us</strong>
          </div>
          <h6 className='heading'>About Us</h6>
          <p className='content'>S&S Fashions has been in business since 1995.We have been selling Popular Brands at great value prices. We make custom products to your specs and colors in our own factories in India. We produce our own line as well.  We also can Screen Print and Embroider in India and can offer you full package. Our goal is to provide the market place with products at sharp prices with great service and amazing quality. Join us and become part of our family. We create a comfortable T-Shirt crafted from superior fabrics. We can offer all sizes and fits to your specifications at value prices. Visit and fall in love with our new catalog. Please enjoy the 2018 version of S&S Fashions Catalog. Same day delivery for New York tri state area if needed. We look forward to meeting you at trade shows. </p>
          <p className='content'>We thank you for choosing S&S Fashions, Inc.</p>
        </div>
        {/* footer  */}
        <Footer/>
    </>
  )
};
export default AboutUs;