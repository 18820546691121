import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import Header from './shared/Header';
import Footer from './shared/Footer';

function OrderDetail() {
  const [inputValues, setInputValue] = useState({
    payment: 'cash',
  });
  const handleChange = (e) => {
    const {name, value} = e.target;
    setInputValue({...inputValues, [name]: value});
  }
  return (
    <>
    <Header/>
    <div className="cart-section page-spacing">
        <div className='breadcrumbs'>
            <Link to="/">Home / </Link> <Link to="/shop">Shop / </Link>  <Link to="/cart">Cart / </Link> <strong>OrderDetail</strong>
        </div>
        <div className='row'>
          <div className='col-xl-9'>
          <div className='table-responsive mb-4'>
            <table className="table table-bordered">
              <thead>
                <tr>              	 					
                  <th className='first-child'>ITEM(S)</th>
                  <th>STYLE NAME</th>
                  <th>BRAND NAME</th>
                  <th>COLORS</th>
                  <th>SIZE</th>
                  <th>PRICE</th>
                  <th>QTY</th>
                  <th>SUB TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <>
                  <tr>
                    <td rowSpan={3} className='first-child'>                  
                      <img className='product-image me-2' src="/images/women-1.jpg" alt="product"/>
                      <h6 className='product-name mt-2'>Product Name</h6>                                                
                    </td>
                    <td rowSpan={3}>8880</td>
                    <td rowSpan={3}>J. America</td>
                    <td>Black Triblend</td>
                    <td>S</td>
                    <td>$14.34</td>
                    <td>1</td>
                    <td>$43.02</td>
                  </tr>
                  <tr>
                    <td>Black Triblend</td>
                    <td>M</td>
                    <td>$14.34</td>
                    <td>1</td>
                    <td>$43.02</td>
                  </tr>
                  <tr>
                    <td>Black Triblend</td>
                    <td>L</td>
                    <td>$14.34</td>
                    <td>1</td>
                    <td>$43.02</td>
                  </tr>
                </> 
                <>
                  <tr>
                    <td rowSpan={3} className='first-child'>                  
                      <img className='product-image me-2' src="/images/women-1.jpg" alt="product"/>
                      <h6 className='product-name mt-2'>Product Name</h6>                                                
                    </td>
                    <td rowSpan={3}>8880</td>
                    <td rowSpan={3}>J. America</td>
                    <td>Black Triblend</td>
                    <td>S</td>
                    <td>$14.34</td>
                    <td>1</td>
                    <td>$43.02</td>
                  </tr>
                  <tr>
                    <td>Black Triblend</td>
                    <td>M</td>
                    <td>$14.34</td>
                    <td>1</td>
                    <td>$43.02</td>
                  </tr>
                  <tr>
                    <td>Black Triblend</td>
                    <td>L</td>
                    <td>$14.34</td>
                    <td>1</td>
                    <td>$43.02</td>
                  </tr>
                </>         
                
                <tr>
                  <th colSpan={7} className='text-end'>Total</th>
                  <td>$343.02</td>
                </tr>
              </tbody>
            </table>
          </div>
          </div>
          <div className='col-xl-3'>
            <div className='card'>
              <div className="card-body">
                <h6 className='title'>ORDER NOTES & SUMMARY</h6>
                <div className='order-detail'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <span>Total</span>	<strong>$343.02</strong>                         	
                  </div>
                  <div className='d-flex justify-content-between align-items-center'>
                    <span>Shipping Cost</span>	<strong>$18.43</strong>                          	
                  </div>
                  <div className='d-flex justify-content-between align-items-center'>
                    <span>Grand Total</span>	<strong>$361.45</strong>                          	
                  </div>
                </div>
                <p className='tagline'>Please write notes of your order</p>
                <textarea className='order-notes' placeholder='Order Notes'></textarea>
                <hr/>
                <h6 className='title'>Payment Methods</h6>
                <p className='tagline'>Please select a prefered payment method to use on this order.</p>
                <label className="payment-label">Cash on Pickup
                  <input type="radio" checked={inputValues.payment === 'cash'} id="cash" name="payment" value="cash" onChange={(e) => handleChange(e)}/>
                  <span className="checkmark"></span>
                </label>
                <label className="payment-label">Paypal
                  <input type="radio" checked={inputValues.payment === 'paypal'} id="paypal" name="payment" value="paypal" onChange={(e) => handleChange(e)}/>
                  <span className="checkmark"></span>
                </label>
                <label className="payment-label">Quickpay by Zelle
                  <input type="radio" checked={inputValues.payment === 'zelle'} id="zelle" name="payment" value="zelle" onChange={(e) => handleChange(e)}/>
                  <span className="checkmark"></span>
                </label>
                <div className='d-flex justify-content-center mt-4'>
                  <Link to='/order-success' className='buttons'>Place Order</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <Footer/>
    </>
  )
};

export default OrderDetail;