import React, {useState} from 'react';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import appConstant from './constant/constant.json';
import { Link } from 'react-router-dom';

function ForgotPassword() {
    const [isLoading, setIsLoading] = useState(false);
    const [resultMessage, setResultMessage] = useState('');   
    // forgot password 
    const [forgotInputValues, setForgotInputValues] = useState({
        email : ""
    });
    const handleForgotChange = (e) => {
        const {name, value} = e.target;
        setForgotInputValues({...forgotInputValues, [name]: value});
    }
    const [forgotValidation, setforgotValidation] = useState({
        email : ""
    });
    const checkForgotValidation = () => {
        let errors = JSON.parse(JSON.stringify(forgotValidation));
    
        // email validation
        const emailCond = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!forgotInputValues.email) {
          errors.email = "Email is required";
        } else if (!forgotInputValues.email.match(emailCond)) {
          errors.email = "Please enter valid email address";
        } else {
          errors.email = "";
        }
        setforgotValidation(errors);
        if(errors.email === ''){
          return true;
        }else{
          return false;
        }
    };
    const forgotPassword = (e) => {
        e.preventDefault();
        const isSuccess = checkForgotValidation();
        if(isSuccess) {
            setIsLoading(true);
            const forgotData = new FormData();
            forgotData.append('email', forgotInputValues.email);
            const params = {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            };
            axios.post(`${appConstant.API_URL}/user/forget_password`, forgotData, params)
            .then(res => {
              setIsLoading(false);
              if (res.data.status === 200) {
                  alert(res?.data?.message);
                  window.location.href = window.location;
                // setResultMessage(res?.data?.message);
                // setForgot(false);
                // setReset(true);
              }else {
                setResultMessage(res.data.error);
              }
            })
            .catch(error => {
              setIsLoading(false);
            });                    
        }        
    }
  return (
    <>
        <Header/>
        <div className='static-page-banner'></div>
          <div className='static-page-content page-spacing mb-4'> 
            <h6 className='heading'>Forgot Password</h6>  
            <div className='user-content'>
              {resultMessage && <p className='formErrors mb-2'>{resultMessage}</p>}
              <form className='forgot-form' onSubmit={forgotPassword}>
                  <label>Email:</label>
                  <input type="text" placeholder="Enter Email" name="email" value={forgotInputValues.email} onChange={(e) => handleForgotChange(e)}/>
                  {forgotValidation.email && <p className='formErrors'>{forgotValidation.email}</p>}
                  {isLoading ? <div className='text-center mb-4'><button type='submit' className="buttons">Submit <img className='ms-2' src='/images/default.gif' width={'16px'} height={'16px'}/></button></div> :
                      <div className='text-center mb-4'><button type='submit' className="buttons">Submit</button></div>
                  }
                  <h6 className='tagline mb-2 text-center'>OR</h6>
                  <h6 className='tagline text-center'><Link className='links' to='/login'>Login</Link> | <Link to='/signup' className='links'>Signup</Link></h6>
              </form>                        
            </div>                             
        </div>
        {/* footer  */}
        <Footer/> 
    </>
  )
};

export default ForgotPassword;